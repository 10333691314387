(function(ng) {
    angular
        .module('fca.newInventory.otherVehicles')
        .component('newInventoryOtherVehicles', {
            controller: NewInventoryOtherVehicles,
            controllerAs: '$ctrl',
            templateUrl: '/new-inventory/other-vehicles/other-vehicles.html',
            bindings: {
                vin: "@",
                brandCode: "@",
                userLocation: "<",
                sniDetailsUrl: "@",
                eshopUrl: "@",
                eshopWindow: "@",
                eshopActivated: "@",
                eshopLabel: "@",
                dealerDisclaimer: "@",
                financeDisclaimer: "@",
                netDisclaimer: "@",
                leaseDisclaimer: "@",
                disclaimerLegAmountSni: "@",
                maxIrisWidth: "@",
                campaignCode: "@?"
            }
        });

    function NewInventoryOtherVehicles($http, fcaSniTrimService) {
        'ngInject';

        const $ctrl = this;

        $ctrl.paymentMode = "cash";
        $ctrl.language = window.FCA_SITES_CONFIG.language;
        $ctrl.campaignParams = '';

        $ctrl.$onInit = () => {
            $ctrl.disclaimers = {
                dealer: $ctrl.dealerDisclaimer,
                regular: $ctrl.netDisclaimer,
                finance: $ctrl.financeDisclaimer,
                lease: $ctrl.leaseDisclaimer,
                legAmountSni: $ctrl.disclaimerLegAmountSni
            }

            $ctrl.eshopTarget = "_top";
            if ($ctrl.eshopWindow == "true") {
                $ctrl.eshopTarget = "_blank";
            }
            $ctrl.checkAffiliateMode();
            $ctrl.checkCampaign();

            $ctrl.fetchAPIData();
        }

        $ctrl.checkAffiliateMode = () => {
            storeAffiliateId();
            $ctrl.affiliateMode = isAffiliateMode();
            $ctrl.affiliateId = $ctrl.affiliateMode ? getStoredAffiliateId() : null;

            if($ctrl.affiliateMode && $ctrl.affiliateId) {
                $ctrl.affiliateUrl = getAffiliateIdUrlParam('?'); 
            }
        };

        $ctrl.checkCampaign = () => {
            if ($ctrl.campaignCode !== null && $ctrl.campaignCode !== 'null'
                && $ctrl.campaignCode !== '') {
                window.FcaCookieChecker.addSessionStorage('campaignCode', $ctrl.campaignCode);
                $ctrl.campaignParams = `campaignCode=${$ctrl.campaignCode}`;
            } else {
                sessionStorage.removeItem('campaignCode');
            }
        };

        $ctrl.fetchAPIData = () => {
            let ajaxUrl = (
                `/data/inventories/${$ctrl.brandCode}/` +
                `vehicle/otherVehicles/${$ctrl.vin.toUpperCase()}`
            );

            ajaxUrl += '?nbItems=3';

            if ($ctrl.affiliateMode && $ctrl.affiliateId) {
                ajaxUrl += getAffiliateIdUrlParam();
            }

            if ($ctrl.campaignCode) {
                ajaxUrl += `&${$ctrl.campaignParams}`;
            }

            if ($ctrl.userLocation && $ctrl.userLocation.longitude) {
                ajaxUrl += `&longitude=${$ctrl.userLocation.longitude}`;
                ajaxUrl += `&latitude=${$ctrl.userLocation.latitude}`;
            }

            $http.get(ajaxUrl).then(response => {
                $ctrl.vehicles = response.data;
                fcaSniTrimService.setMfgCodeForMultipleVehicles($ctrl.vehicles,
                    $ctrl.maxIrisWidth);
            }, error => {
                console.error(error);
            })
        }
    }
})(angular);