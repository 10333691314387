(function(ng) {
	'use strict';

	angular
		.module('fca.commonDialog')
		.directive('commonFcaDialog', directiveDirective);

	function directiveDirective(
		$log,
		ngDialog,
		$rootScope,
		fcaDialogTheme,
		$timeout) {
		'ngInject';

		let scrollableCheckTimeout;
		let scroll;
		function checkScrollableDialog(delay = 1000) {
			let $dialog = $('.ngdialog');
			let $dialogOverlay = $('.ngdialog-overlay', $dialog);
			let $dialogContent = $('.ngdialog-content', $dialog);
			$dialogOverlay.removeClass('scrollable');
			$dialog.attr('role', 'dialog');
			$dialog.attr('aria-modal', 'true');

			if (scrollableCheckTimeout) {
				$timeout.cancel(scrollableCheckTimeout);
			}

			scrollableCheckTimeout = $timeout(() => {
				let $dialog = $('.ngdialog');
				if ($dialog.length) {
					let top = $dialog.css('padding-top').replace('px', '');
					let bottom = $dialog.css('padding-bottom').replace('px', '');
					let $dialogPadding = parseInt(top, 10) + parseInt(bottom, 10);
					let dialogHeight = $dialog.outerHeight();
					let dialogContentHeight = $dialogContent.outerHeight() + $dialogPadding;
					if (dialogHeight < dialogContentHeight) {
						$dialogOverlay.addClass('scrollable');
					} else {
						$dialogOverlay.removeClass('scrollable');
					}
				}
			}, delay);
		}

		$(window).on('resize', () => {
			checkScrollableDialog(500);
		});

		return {
			restrict: 'EAC',
			scope: {
				onClose: '&',
				dialogModel: '@?',
				dialogModelOverride: '@?',
				dialogLocation: '<?'
			},
			link: ($scope, $elt, $attrs) => {

				if ($attrs && $attrs.isMediaCarousel === 'true') {
					let cfgs = getDialogCfgs();
					ngDialog.open(cfgs);
				}


				function getDialogCfgs() {
					let theme = $attrs.theme || 'default';
					let appendTo = $attrs.target || null;
					let closeCallbackReference = $scope.onClose;
					let defaultCfgs = fcaDialogTheme.get(theme, {});
					let controller = $attrs.dialogCtrl || defaultCfgs.controller;
					let template = $attrs.dialogTpl || defaultCfgs.template;

					let dialogModel = {};
					if (ng.isString($scope.dialogModel)) {
						dialogModel = ng.fromJson($scope.dialogModel);
					}

					let dialogModelOverride = {};
					if (ng.isString($scope.dialogModelOverride)) {
						dialogModelOverride = ng.fromJson($scope.dialogModelOverride);
					}

					let dialogLocation = {location: null};
					if ($scope.dialogLocation && ng.isObject($scope.dialogLocation)) {
						dialogLocation.location = $scope.dialogLocation;
					}

					// Override dialogModel with dialogModelOverride
					dialogModel = ng.extend({}, dialogModel, dialogModelOverride, dialogLocation);

					/* Build set theme configurations + override */
					let cfgs = fcaDialogTheme.get(theme, {
						controller: controller,
						template: template,
						preCloseCallback: (data) => {
							/* Push data outside overlay */
							if (data !== undefined && ng.isFunction(closeCallbackReference)) {
								closeCallbackReference({
									data: data
								});
							}
						},
						appendTo: appendTo,
						data: dialogModel,
						onOpenCallback: () => {
							checkScrollableDialog();
						}
					});

					return cfgs;
				}

				$elt.on('click', (evt) => {
					evt.preventDefault();
					/* Fake click hack bug FCAPODS-1915 :  closing Cookie Pro modal
					 fix conflick between Force Geoloc Modal and Cookie Pro Modal
					* To be remove once DealerVisibility project is merged */
					if(evt.currentTarget && evt.currentTarget.getAttribute('data-fake-click-hack')) {
						evt.currentTarget.removeAttribute('data-fake-click-hack')
						evt.stopPropagation();
					}
					let cfgs = getDialogCfgs();
					scroll = $(window).scrollTop();

					/* Open modal dialog */

					ngDialog.open(cfgs);
				});

				$rootScope.$on('reset-scroll-on-close-dialog', () => {
					document.querySelector('body').classList.remove("ngdialog-open");
					$timeout(() => {
						$(window).scrollTop(scroll);
					});
				});

				$('body.ngdialog-open').on('touchmove', function(e) {
					e.preventDefault();
				}, false);
			}
		};
	}
})(angular);
