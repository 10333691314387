/* eslint-disable indent */
(function () {
    angular
        .module('fca.contactADealer', ['ngMessages', 'ngTouch'])
        .component('fcaContactADealer', {
            templateUrl: ['$element', '$attrs', function ($element, $attrs) {
				return $attrs.templateUrl || '/contact-a-dealer/contact-a-dealer.html';
            }],
            controller: ContactADealer,
            controllerAs: '$ctrl',
            bindings: {
                apiUrl: '@',
                contactADealerThankYouUrl: '@',
                showPostalCode: '<',
                location: "<",
                acode: "@",
                mfgCode: "@",
                year: "@",
                brandCode: "@",
                jellyPov: "@",
                jellyMobileWidth: "@",
                jellyMobileHeight: "@",
                desktopWidth: "@",
                desktopHeight: "@",
                mobileMaxWidth: "@",
                mobileInitialMaxDealers: "@",
                mobileDealerIncrement: "@",
                languageLink: "@",
                getNotifiedOnSucceed: "<",
                kbbVehicleInfo: "<",
                showDealerAppointmentSection: '<',
            }
        });

    function ContactADealer($location, $scope, $rootScope, $element, $http, $window, $timeout, dealerLocator, fcaSalesAppointmentService, gtmAnalytics, contactADealerService) {
        'ngInject';

        let $ctrl = this;
        $ctrl.vehicleObject = [];
        $ctrl.language = FCA_SITES_CONFIG.language;
        $ctrl.hashParametersApplied = false;
        $ctrl.screenWidth = 0;

        $ctrl.fullPageMode = true;
        $ctrl.buildAndPriceMode = false;
        $ctrl.sniMode = false;

        $ctrl.brands = [];
        $ctrl.currentBrand = [];
        $ctrl.brand = ``;
        $ctrl.brandCode = "";
        $ctrl.currentBrandCode = "";
        $ctrl.currentSubBrandCode = "";
        $ctrl.currentBrandName = "";

        $ctrl.nameplatesYears = {};
        $ctrl.combinedNamePlatesYears = [];
        $ctrl.nameplateCode = "";
        $ctrl.nameplateYear = ``;
        $ctrl.nameplate = ``;
        $ctrl.year = "";

        $ctrl.trimGroups = {};
        $ctrl.trimGroup = ``;
        $ctrl.trimGroupCode = "";
        $ctrl.modelYearId = "";
        $ctrl.mfgCode = "";
        $ctrl.fullVehicle = "";
        $ctrl.selectedTrim = "";
        $ctrl.acode = "";
        $ctrl.provinceCode = "";
        $ctrl.scratchSave = "";

        $ctrl.dealers = [];
        $ctrl.dealer = "";
        $ctrl.currentDealerData = [];
        $ctrl.maxAmountOfDealers = 3;
        $ctrl.mobileMaxDealers = 0;
        $ctrl.intNbOfDealersSelected = 0;

        $ctrl.dealerDisabled = false;
        $ctrl.findDealersTriggered = false;
        $ctrl.sniVehicles = [];
        $ctrl.vehicleInventory = [];

        $ctrl.selectedDealers = [];
        $ctrl.appointmentDate = "";
        $ctrl.appointmentTime = "";
        $ctrl.isMultipleDealersMode = true;
        $ctrl.leadId = "";
        $ctrl.existAppointment = false;
        $ctrl.appointmentErrorMsg = null;
        $ctrl.isTradeIn = false;

        $ctrl.hashParameters = {};
        $ctrl.reason = "";
        $ctrl.postType = "";
        $ctrl.vins = [];

        $ctrl.objJellyTrimGroupUrl = {};
        $ctrl.jellyUrl = "";
        $ctrl.allJellyUrls = "";
        $ctrl.rootIrisUrl = FCA_SITES_CONFIG.irisJellyBaseUrl + "/mediaserver/iris?client=FCAUS&market=U";

        $ctrl.legacyRootURl = FCA_SITES_CONFIG.jelliesUrl.replace('jellies/renditions/', '');
        $ctrl.vehicleType = "";
        $ctrl.paint = "";
        $ctrl.sa = "";
        $ctrl.vehicle = "";
        $ctrl.mfgCode = "";
        $ctrl.jellyBrandCode = "";
        $ctrl.jellyType = "";

        $ctrl.brandSelectField = "brandSelector";
        $ctrl.nameplateSelectField = "nameplateSelector";
        $ctrl.trimGroupSelectField = "trimGroupSelector";
        $ctrl.vehicleTileField = "vehicleCardTile";

        $ctrl.activeSlide = 0;
        $ctrl.carouselSlideWidth = 0;
        $ctrl.carouselSlideLeftValue = 0;
        $ctrl.nextSlideActivated = false;
        $ctrl.previousSlideActivated = false;
        $ctrl.specificSlideActivated = false;
        $ctrl.window = angular.element($window);

        $ctrl.visibleSelectField = "";
        $ctrl.phoneRegex = '^\\(?([2-9][0-9]{2})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$';
        $ctrl.postalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
        $ctrl.formErrors = [];
        $ctrl.contactADealerLoading = false;
        $ctrl.showTrimGroupsTriggered = false;

        $ctrl.inIframeMode = false;
        $ctrl.queryParams = '';

        $ctrl.errorMsgVisible = false;

        $ctrl.firstNameHasEmptyValue = true;
        $ctrl.lastNameHasEmptyValue = true;
        $ctrl.hasEmptyValue = true;

        $ctrl.firstNameHasInvalidValue = false;
        $ctrl.lastNameHasInvalidValue = false;
        $ctrl.hasInvalidValue = false;

        $ctrl.hasTrimGroupPackage = false;

		$ctrl.isElectricVehicle = false;

        $ctrl.$onInit = () => {
            $ctrl.reason = "get_a_quote";
            $ctrl.inIframeMode = $location.search()['view-name'] === 'headless_renderer';
            $ctrl.queryParams = addQueryParametersToUrl($ctrl.queryParams);
            $ctrl.getVehicleSelectorData().then((r) => {
                $ctrl.brandCode = FCA_SITES_CONFIG.name;
                $ctrl.currentBrandCode = FCA_SITES_CONFIG.subBrandCode || $ctrl.brandCode;
                $ctrl.applyHashParameters();
                $ctrl.writeUrlHash();
                $ctrl.checkIfDealerCodeVinOrAllDealers();

                if ($ctrl.scratchSave !== "") {
                    $ctrl.fullPageMode = false;
                    $ctrl.buildAndPriceMode = true;
                    $ctrl.sniMode = false;
                    $ctrl.getNameplateNameFromCode($ctrl.nameplateCode);
                    $ctrl.getVehicleJellyByACode();
					$ctrl.setElectricVehicleState();
                } else if ($ctrl.vins.length) {
                    $ctrl.fullPageMode = false;
                    $ctrl.buildAndPriceMode = false;
                    $ctrl.sniMode = true;
                } else {
                    $ctrl.fullPageMode = true;
                    $ctrl.buildAndPriceMode = false;
                    $ctrl.sniMode = false;
                }
            });

            $ctrl.screenWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
            $ctrl.mobileMaxDealers = parseInt($ctrl.mobileInitialMaxDealers);

            $ctrl.dimensions = {
                mobile: {
                    mediaQuery: '(min-width: 1px)',
                    mediaQueryRetina: '(min-width: 1px) and (-webkit-min-device-pixel-ratio: 2),(min-width: 1px) and (min--moz-device-pixel-ratio: 2),(min-width: 1px) and (-o-min-device-pixel-ratio: 2/1),(min-width: 1px) and (min-device-pixel-ratio: 2),(min-width: 1px) and (min-resolution: 192dpi),(min-width: 1px) and (min-resolution: 2dppx)',
                    width: $ctrl.mobileWidth,
                    height: $ctrl.mobileHeight
                },
                tablet: {
                    mediaQuery: '(min-width: 768px)',
                    mediaQueryRetina: '(min-width: 768px) and (-webkit-min-device-pixel-ratio: 2),(min-width: 768px) and (min--moz-device-pixel-ratio: 2),(min-width: 768px) and (-o-min-device-pixel-ratio: 2/1),(min-width: 768px) and (min-device-pixel-ratio: 2),(min-width: 768px) and (min-resolution: 192dpi),(min-width: 768px) and (min-resolution: 2dppx)',
                    width: $ctrl.desktopWidth,
                    height: $ctrl.desktopHeight
                },
                desktop: {
                    mediaQuery: '(min-width: 1025px)',
                    mediaQueryRetina: '(min-width: 1025px) and (-webkit-min-device-pixel-ratio: 2),(min-width: 1025px) and (min--moz-device-pixel-ratio: 2),(min-width: 1025px) and (-o-min-device-pixel-ratio: 2/1),(min-width: 1025px) and (min-device-pixel-ratio: 2),(min-width: 1025px) and (min-resolution: 192dpi),(min-width: 1025px) and (min-resolution: 2dppx)',
                    width: $ctrl.desktopWidth,
                    height: $ctrl.desktopHeight
                }
            };
            $ctrl.background = "white";

            $rootScope.$on('contact-a-dealer.trigger-submit-form', (evt, args) => {
                $ctrl.isTradeIn = args.tradeIn;
                $timeout(() => {
                    $ctrl.submitLeadAjax();
                });
            });

            fcaSalesAppointmentService.registerAppointmentListener($ctrl.selectAppointment);
        };

        $ctrl.$onChanges = (changes) => {
            if ($ctrl.hashParametersApplied && $ctrl.dealer === '') {
                $ctrl.findDealers();
            }
            if (changes.kbbVehicleInfo) {
                $ctrl.kbbDealerVehicleInfo = $ctrl.kbbVehicleInfo;
            }

            $ctrl.selectedDealers = $ctrl.dealers.filter((element) => element.selected === true);
        };

        $ctrl.window.bind('resize', function () {
            $ctrl.screenWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
        });

        $ctrl.getVehicleSelectorData = () => {
            return $http.get(`/api/lead/${$ctrl.language}/vehicle-selector`).then((result) => {
                let vehicleData = result.data;
                $ctrl.vehicleObject = vehicleData;
                for (let intBrands = 0; intBrands < $ctrl.vehicleObject.length; intBrands++) {
                    // Loops through all the brands to store them inside the brands object
                    let arrBrands = $ctrl.vehicleObject[intBrands];
                    $ctrl.brands[intBrands] = {
                        "brandCode": arrBrands.code,
                        "brandName": arrBrands.name,
                        "subBrandCode": arrBrands.subBrandCode||'',
                        "nameplates": arrBrands.children
                    };
                }
                $ctrl.brandCode = FCA_SITES_CONFIG.name;
                $ctrl.currentBrandCode = FCA_SITES_CONFIG.subBrandCode || $ctrl.brandCode;
                if ($ctrl.currentBrandCode === 'fiat') {
                    $ctrl.brands = $ctrl.brands.filter((element) => element.brandCode === $ctrl.currentBrandCode);
                } else {
                    $ctrl.brands = $ctrl.brands.filter(function(element) {
                        return element.brandCode !== 'fiat';
                    });
                }
            });
        };

        $ctrl.getVehicleJelly = (argBrandCode, argNameplateCode, argYear, argModelYearId) => {
			let brandCode = argBrandCode;
            let nameplateCode = argNameplateCode;
            let year = argYear;
            let modelYearId = argModelYearId;
            const jellyApiUrl = `/api/lead/${$ctrl.language}/brand/${brandCode}/nameplate/${nameplateCode}/year/${year}/modelyear/${modelYearId}/trimgroup`;
            return $http.get(jellyApiUrl).then((result) => {
                $ctrl.objJellyTrimGroupUrl = result.data;
                if ($ctrl.objJellyTrimGroupUrl.legacyJellyUrl) {
                    $ctrl.jellyType = 'legacy';
                    $ctrl.jellyUrl = $ctrl.objJellyTrimGroupUrl.legacyJellyUrl;
                } else if ($ctrl.objJellyTrimGroupUrl.hasOwnProperty('trimIrisJellyOptions') && $ctrl.objJellyTrimGroupUrl.trims[0].packages.length) {
                    $ctrl.jellyType = 'iris';
                    $ctrl.vehicleType = $ctrl.objJellyTrimGroupUrl.hasOwnProperty('cposVehicleType') ? $ctrl.objJellyTrimGroupUrl.trimIrisJellyOptions.cposVehicleType : '';
                    if (!$ctrl.hashParameters.paint) {
                        $ctrl.paint = $ctrl.objJellyTrimGroupUrl.trims[0].primaryColourCode;
                    }
                    $ctrl.mfgCode = $ctrl.objJellyTrimGroupUrl.trims[0].mfgCode;
                    $ctrl.jellyBrandCode = $ctrl.brandCode.substr(0, 1).toUpperCase();
                    if ($ctrl.brandCode === 'fiat') {
                        $ctrl.jellyBrandCode = 'X';
                    }
                    if ($ctrl.brandCode === 'alfaromeo') {
                        $ctrl.jellyBrandCode = 'Y';
                    }
                    if ($ctrl.vehicleType !== '') {
                        $ctrl.sa =
                            $ctrl.mfgCode + "," + $ctrl.objJellyTrimGroupUrl.trims[0].packages[0].code.substr(0, 1) +
                            $ctrl.vehicleType + $ctrl.objJellyTrimGroupUrl.trims[0].packages[0].code.substr(2, 1) +
                            "," + $ctrl.objJellyTrimGroupUrl.trims[0].packages[0].code;
                    } else {
                        $ctrl.sa =
                            $ctrl.mfgCode + "," + $ctrl.objJellyTrimGroupUrl.trims[0].packages[0].code.substr(0, 1) +
                            "D" + $ctrl.objJellyTrimGroupUrl.trims[0].packages[0].code.substr(2, 1) +
                            "," + $ctrl.objJellyTrimGroupUrl.trims[0].packages[0].code;
                    }
                    $ctrl.vehicle = year + "_" + $ctrl.mfgCode.substr(0, 2);
					$ctrl.jellyUrl =
                        `${$ctrl.rootIrisUrl}&brand=${$ctrl.jellyBrandCode}` +
                        `&vehicle=${$ctrl.vehicle}&paint=${$ctrl.paint}&sa=${$ctrl.sa}` +
                        `&pov=${$ctrl.jellyPov}&width=${$ctrl.jellyMobileWidth}` +
                        `&height=${$ctrl.jellyMobileHeight}&bkgnd=${$ctrl.background}`;
                    $ctrl.allJellyUrls = "";

                    // Mobile
                    $ctrl.allJellyUrls += "['" + $ctrl.generateSingleIrisUrl($ctrl.dimensions.mobile.mediaQuery.url,
                        $ctrl.dimensions.mobile.width, $ctrl.dimensions.mobile.height, $ctrl.jellyParameters, 1) + "']";
                    // Mobile Retina
                    $ctrl.allJellyUrls += "['" + $ctrl.generateSingleIrisUrl($ctrl.dimensions.mobile.mediaQueryRetina.url,
                        $ctrl.dimensions.mobile.width, $ctrl.dimensions.mobile.height, $ctrl.jellyParameters, 2) + "']";
                    // Tablet
                    $ctrl.allJellyUrls += "['" + $ctrl.generateSingleIrisUrl($ctrl.dimensions.tablet.mediaQuery.url,
                        $ctrl.dimensions.tablet.width, $ctrl.dimensions.tablet.height, $ctrl.jellyParameters, 1) + "']";
                    // Tablet Retina
                    $ctrl.allJellyUrls += "['" + $ctrl.generateSingleIrisUrl($ctrl.dimensions.tablet.mediaQueryRetina.url,
                        $ctrl.dimensions.tablet.width, $ctrl.dimensions.tablet.height, $ctrl.jellyParameters, 2) + "']";
                    // Desktop
                    $ctrl.allJellyUrls += "['" + $ctrl.generateSingleIrisUrl($ctrl.dimensions.desktop.mediaQuery.url,
                        $ctrl.dimensions.desktop.width, $ctrl.dimensions.desktop.height, $ctrl.jellyParameters, 1) + "']";
                    // Desktop Retina
                    $ctrl.allJellyUrls += "['" + $ctrl.generateSingleIrisUrl($ctrl.dimensions.desktop.mediaQueryRetina.url,
                        $ctrl.dimensions.desktop.width, $ctrl.dimensions.desktop.height, $ctrl.jellyParameters, 2) + "']";
                }
                if ($ctrl.jellyUrl !== "") {
                    $ctrl.checkIfJellyIsLoading();
                }
            });
        };

        $ctrl.getVehicleJellyByACode = () => {
            const fileApiUrl = `/data/jellies/files/brand/${$ctrl.brandCode}/year/${$ctrl.year}/acode/${$ctrl.acode}`;
            return $http.get(fileApiUrl).then(result => {
				$ctrl.jellyFiles = result.data;
                $ctrl.filterVehicleJellyByPaint();
            }, reason => {
                console.error(reason);
            })
        };

        $ctrl.filterVehicleJellyByPaint = () => {
			let jellyData = $ctrl.jellyFiles.filter((element) => element.filename.includes($ctrl.paint));

            if (!jellyData[0]) {
                jellyData = $ctrl.jellyFiles.filter((element) => element.filename.includes('Not_Available'));
            }
            if (jellyData[0]) {
                let filename = jellyData[0].filename.split('.')[0];
                const checksum = jellyData[0].checksum;

                $ctrl.jellyUrl = `${$ctrl.legacyRootURl}jellies/` +
                    `renditions/${$ctrl.year}/800x510/` +
                    `${filename.replace('.png', '')}.${checksum}.png`;
                $ctrl.jellyType = 'legacy';
                $ctrl.checkIfJellyIsLoading();
            }
        };

        $ctrl.generateSingleIrisUrl = (mediaQuery, baseUrl, width, height, parameters, pixelDensity) => {
            let url = mediaQuery + "', '" + baseUrl;
            url += "&width=" + (width * pixelDensity);
            if (height !== undefined) {
                url += "&height=" + (height * pixelDensity);
            }
            if (parameters !== undefined) {
                url += "&" + $ctrl.jellyParameters;
            }
            url += "&bkgnd=" + $ctrl.background;
            return url;
        };

        $ctrl.loaded = () => {
            $('.C_CAD-form-vehicleCard-jellyContainer-loader').hide();
        };

        $ctrl.loading = () => {
            $('.C_CAD-form-vehicleCard-jellyContainer-loader').show();
        };

        $ctrl.checkIfJellyIsLoading = () => {
            $('.C_CAD-jellyImage').on('load', $ctrl.loaded);
            $ctrl.loading();
        };

        $ctrl.checkIfDealerCodeVinOrAllDealers = () => {
            if ($ctrl.vins.length && $ctrl.dealer === "") {
                for (let i = 0; i < $ctrl.vins.length; i++) {
                    $ctrl.fetchVinDetails($ctrl.vins[i].toUpperCase());
                }

            } else if ($ctrl.dealer === "" && !$ctrl.vins.length && !$ctrl.dealers.length) {
                $ctrl.findDealers($ctrl.location);
            } else if ($ctrl.dealer !== "" && !$ctrl.vins.length) {
                $ctrl.fetchASingleDealer();
            }
        };

        $ctrl.applyHashParameters = () => {
            let hash = $location.hash();
            if (hash) {
                $ctrl.hashParameters = getHashParameters();

                if ($ctrl.hashParameters.reason) {
                    $ctrl.reason = $ctrl.hashParameters.reason;
                }

                if ($ctrl.hashParameters.brandCode) {
                    $ctrl.brandCode = $ctrl.hashParameters.brandCode;
                }
                // also accept brand parameter for brand code
                if ($ctrl.hashParameters.brand) {
                    $ctrl.brandCode = $ctrl.hashParameters.brand;
                }

                if ($ctrl.hashParameters.nameplateCode) {
                    $ctrl.nameplateCode = $ctrl.hashParameters.nameplateCode;
                }

                // also accept nameplate parameter for nameplate code
                if ($ctrl.hashParameters.nameplate) {
                    $ctrl.nameplateCode = $ctrl.hashParameters.nameplate;
                }

                if ($ctrl.hashParameters.year) {
                    $ctrl.year = $ctrl.hashParameters.year;
                }

                if ($ctrl.hashParameters.trim) {
                    $ctrl.trimGroup = $ctrl.hashParameters.trim;
                }
                if ($ctrl.hashParameters.dealer) {
                    $ctrl.dealer = $ctrl.hashParameters.dealer;
                }
                const dealerId = sessionStorage.getItem('dealerId');
                if (dealerId) {
                    $ctrl.dealer = dealerId;
                    sessionStorage.removeItem('dealerId');
                }
                if ($ctrl.hashParameters.modelYearId) {
                    $ctrl.modelYearId = $ctrl.hashParameters.modelYearId;
                }
                if ($ctrl.hashParameters.acode) {
                    $ctrl.acode = $ctrl.hashParameters.acode;
                }
                if ($ctrl.hashParameters.paint) {
                    $ctrl.paint = $ctrl.hashParameters.paint;
                }
                if ($ctrl.hashParameters.mfgCode) {
                    $ctrl.mfgCode = $ctrl.hashParameters.mfgCode;
                }
                if ($ctrl.hashParameters.provinceCode) {
                    $ctrl.provinceCode = $ctrl.hashParameters.provinceCode;
                }
                if ($ctrl.hashParameters.scratchSave) {
                    $ctrl.scratchSave = $ctrl.hashParameters.scratchSave;
                }
                if ($ctrl.hashParameters.vins) {
                    if ($ctrl.hashParameters.vins.includes(',')) {
                        $ctrl.vins = $ctrl.hashParameters.vins.split(',');
                    } else {
                        $ctrl.vins.push($ctrl.hashParameters.vins);
                    }
                }
                //  If the needed hash parameters to select a car exist, then we select the car and skip the vehicle selector
                if ($ctrl.brandCode !== "" && $ctrl.nameplateCode !== "" && $ctrl.year !== "" && ($ctrl.trimGroupCode !== "" || $ctrl.brandCode === 'alfaromeo')) {
                    $ctrl.changeVisibleSelectField($ctrl.vehicleTileField);
                    $ctrl.setCurrentBrandCode($ctrl.currentBrandCode);
                    $ctrl.showNamePlates($ctrl.brandCode);
                    $ctrl.getBrandName($ctrl.brandCode);
                    $ctrl.getNameplateNameFromCode($ctrl.nameplateCode);
                    $ctrl.showTrimGroups($ctrl.nameplateCode, $ctrl.year);
                } else if ($ctrl.brandCode !== "" && $ctrl.nameplateCode !== "" && $ctrl.year !== "") {
                    $ctrl.setCurrentBrandCode($ctrl.currentBrandCode);
                    $ctrl.showNamePlates($ctrl.brandCode);
                    $ctrl.showTrimGroups($ctrl.nameplateCode, $ctrl.year);
                    $ctrl.getBrandName($ctrl.brandCode);
                    $ctrl.getNameplateNameFromCode($ctrl.nameplateCode);
                    // try to match the trim group with trimDesc if possible
                    let trimGroupCode = null;
                    if ($ctrl.trimGroup) {
                        let matchedTrimGroup = $ctrl.trimGroups.find(function (trimGroup) {
                            return trimGroup.trimDesc == $ctrl.trimGroup;
                        });
                        if (matchedTrimGroup) {
                            trimGroupCode = matchedTrimGroup.code;
                        }
                    }
                    if (trimGroupCode) {
                        $ctrl.trimGroupCode = trimGroupCode;
                    } else {
                        // use the first trim group code
                        $ctrl.trimGroupCode = $ctrl.trimGroups[0].code;
                    }

                    $ctrl.getTrimGroupNameAndModelYearId($ctrl.trimGroupCode);
                    if ($ctrl.acode) {
                        $ctrl.getVehicleJellyByACode();
                    } else {
                        $ctrl.getVehicleJelly($ctrl.brandCode, $ctrl.nameplateCode, $ctrl.year, $ctrl.modelYearId);
                    }
                    if ($ctrl.showTrimGroupsTriggered) {
                        $ctrl.changeVisibleSelectField($ctrl.vehicleTileField);
                    }
                } else if ($ctrl.brandCode !== "") {
                    $ctrl.changeVisibleSelectField($ctrl.nameplateSelectField);
                    $ctrl.setCurrentBrandCode($ctrl.currentBrandCode);
                    $ctrl.showNamePlates($ctrl.brandCode);
                    $ctrl.getBrandName($ctrl.brandCode);
                }
            } else if ($ctrl.brandCode !== "") {
                $ctrl.changeVisibleSelectField($ctrl.nameplateSelectField);
                $ctrl.setCurrentBrandCode($ctrl.currentBrandCode);
                $ctrl.showNamePlates($ctrl.brandCode);
                $ctrl.getBrandName($ctrl.brandCode);
            }
            $ctrl.hashParametersApplied = true;
        };

        $ctrl.writeUrlHash = () => {
            const newHash = '#' + $location.hash();
            const newUrl = $ctrl.languageLink + newHash;

            const $desktopHeaderLangLink = $('.main-nav-lang-toggle, .main-nav-lang');
            const $mobileHeaderLangLink = $('.mobile-nav-lang-toggle a');
            const $footerSwitchLangLinks = $('.footer-province-switch-lang, .footer-nav-lang-toggle, .footer-nav-language-cta-');

            if ($desktopHeaderLangLink.length > 0) {
                $desktopHeaderLangLink.on('click', event => {
                    event.stopPropagation();
                    $desktopHeaderLangLink.attr('href', newUrl);
                });
            }

            if ($mobileHeaderLangLink.length > 0) {
                $mobileHeaderLangLink.on('click', event => {
                    event.stopPropagation();
                    $mobileHeaderLangLink.attr('href', newUrl);
                });
            }

            if ($footerSwitchLangLinks.length > 0) {
                $footerSwitchLangLinks.on('click', event => {
                    event.stopPropagation();
                    $footerSwitchLangLinks.attr('href', newUrl);
                });
            }
        };

        $ctrl.vinArrayToString = () => {
            return $ctrl.vins.join();
        };

        $ctrl.changeVisibleSelectField = (fieldToShow, isClickEvent = false) => {
            //  Some vehicles don't have any trim groups, so we skip to the vehicle tile or only have one trim group
            if (fieldToShow === $ctrl.trimGroupSelectField) {
                if ($ctrl.trimGroups.length > 1) {
                    $ctrl.visibleSelectField = fieldToShow;
                } else {
                    if ($ctrl.trimGroups.length === 1) {
                        $ctrl.trimGroup = $ctrl.trimGroups[0].name;
                        $ctrl.trimGroupCode = $ctrl.trimGroups[0].code;
                        $ctrl.getVehicleJelly($ctrl.currentBrandCode, $ctrl.nameplateCode, $ctrl.year, $ctrl.modelYearId);
                    }

                    if (isClickEvent) {
                        $ctrl.visibleSelectField = fieldToShow;
                    } else {
                        $ctrl.visibleSelectField = $ctrl.vehicleTileField;
                    }
                }
            } else {
                $ctrl.visibleSelectField = fieldToShow;
            }
            if ($ctrl.visibleSelectField === $ctrl.vehicleTileField) {
                $ctrl.carouselSlideWidth = $('.C_CAD-form-step-singleDealer-container li').outerWidth();
                $ctrl.carouselSlideLeftValue = $ctrl.carouselSlideWidth * (-1);
                $('.C_CAD-form-step-singleDealer-container li:first').before($('.C_CAD-form-step-singleDealer-container li:last'));
                $('.C_CAD-form-step-singleDealer-container').css({'left': $ctrl.carouselSlideLeftValue});
            }
        };

        $ctrl.clearTrimGroup = () => {
            if ($ctrl.trimGroup !== "") {
                $ctrl.trimGroup = ``;
                $ctrl.trimGroupCode = "";
            }
        };

        $ctrl.showNamePlates = (brandValue) => {
            let nameplates = null;

            nameplates = $ctrl.brands.filter((element) => element.brandCode === brandValue);

            nameplates = $ctrl.getCurrentBrand(nameplates);

            if (nameplates.length === 0 || nameplates.length > 1) {
                nameplates = $ctrl.currentBrand;
            }

            let arrNameplates = {};
            let arrCombinedNameplates = {};
            $ctrl.nameplatesYears = nameplates[0].nameplates;
            for (let i1 = 0; i1 < $ctrl.nameplatesYears.length; i1++) {
                //  loops through all the nameplates and creates an array
                arrNameplates[i1] = {
                    "code": $ctrl.nameplatesYears[i1].code,
                    "name": $ctrl.nameplatesYears[i1].name,
                    "years": $ctrl.nameplatesYears[i1].children
                };
                for (let i2 = 0; i2 < arrNameplates[i1].years.length; i2++) {
                    //  Loops through the previous array to combine each nameplate with each year
                    if (arrNameplates[i1].years[i2].children.length) {
                        arrCombinedNameplates = {
                            "nameplateCode": arrNameplates[i1].code,
                            "nameplateName": arrNameplates[i1].name,
                            "nameplateYear": arrNameplates[i1].years[i2].code,
                            "fullNamePlate": arrNameplates[i1].years[i2].name,
                            "trimGroups": arrNameplates[i1].years[i2].children,
                            "modelYearId": arrNameplates[i1].years[i2].autodataModelYearId
                        };
                        $ctrl.combinedNamePlatesYears.push(arrCombinedNameplates);
                    }
                }
            }
        };

        $ctrl.clearNameplates = (brandValue) => {
            if ($ctrl.combinedNamePlatesYears.length) {
                $ctrl.combinedNamePlatesYears = [];
                $ctrl.trimGroups = [];
                $ctrl.clearTrimGroup();
                $ctrl.nameplateYear = ``;
                $ctrl.nameplate = ``;
                $ctrl.nameplateCode = ``;
                $ctrl.year = "";
                $ctrl.getBrandName(brandValue);
                $ctrl.showNamePlates(brandValue);
            }
        };

        $ctrl.clearJellyUrl = () => {
            $ctrl.jellyUrl = "";
        };

        $ctrl.showTrimGroups = (nameplateValue, nameplateYearValue) => {
            $ctrl.showTrimGroupsTriggered = true;
            let trimGroups = $ctrl.combinedNamePlatesYears.filter((element) => element.nameplateCode === nameplateValue && element.nameplateYear === nameplateYearValue);
            $ctrl.modelYearId = trimGroups[0].modelYearId;
            if (trimGroups[0].trimGroups.length) {
                $ctrl.trimGroups = trimGroups[0].trimGroups;

                $ctrl.trimGroups.forEach(trimGroup => {
                    if (trimGroup.name === '') {
                        trimGroup.name = $ctrl.formatNameplateName($ctrl.nameplate);
                    }
                });
            }
        };

        $ctrl.getBrandCode = (brandValue) => {
            let arrBrand = $ctrl.brands.filter((element) => element.brandName === brandValue);
            arrBrand = $ctrl.getCurrentBrand(arrBrand);
            $ctrl.brandCode = arrBrand[0].brandCode;
        };

        $ctrl.getBrandName = (brandValue) => {
            let arrBrand = $ctrl.brands.filter((element) => element.brandCode === brandValue);
            arrBrand = $ctrl.getCurrentBrand(arrBrand);
            $ctrl.brand = arrBrand[0].brandName;
        };

        $ctrl.getTrimGroupNameAndModelYearId = (trimGroupValue) => {
            let arrTrimGroup = $ctrl.trimGroups.filter((element) => element.code === trimGroupValue);
            $ctrl.trimGroup = arrTrimGroup[0].name;
        };

        $ctrl.validateTrimGroupPackage = () => {
			$ctrl.hasTrimGroupPackage = !!$ctrl.trimGroups.find(element => element.packageDesc);
        }

        $ctrl.getNameplateNameFromCode = (nameplateValue) => {
            let arrNameplate = $ctrl.combinedNamePlatesYears.filter((element) => element.nameplateCode === nameplateValue);
            $ctrl.nameplate = arrNameplate[0].nameplateName;
            $ctrl.nameplateYear = arrNameplate[0].fullNamePlate;
        };

        $ctrl.getCurrentBrand = (arrBrand) => {
            if (arrBrand.length != 1) {
                arrBrand = $ctrl.currentBrand;
            }
            return arrBrand;
        };

        $ctrl.isSubBrand = (codeValue) => {
            return $ctrl.brands.filter((brand) => brand.subBrandCode != "" && brand.subBrandCode === codeValue).length > 0;
        };

        $ctrl.setCurrentBrandCode = (currentBrandCodeValue) => {
            $ctrl.currentSubBrandCode = $ctrl.isSubBrand(currentBrandCodeValue) ? currentBrandCodeValue : "";

            let arrBrand = $ctrl.brands.filter((element) => element.brandCode === $ctrl.brandCode);

            const selectedBrand = $ctrl.brands.find((element) => element.brandCode === currentBrandCodeValue || element.subBrandCode === currentBrandCodeValue);

            if(arrBrand.length === 0) {
                arrBrand = $ctrl.brands.filter((element) => element.subBrandCode === $ctrl.currentSubBrandCode);
            }

            if(arrBrand.length > 1) {
                arrBrand = arrBrand.filter((element) => element.subBrandCode === $ctrl.currentSubBrandCode);
            }

            $ctrl.brandCode = arrBrand[0].brandCode;
            $ctrl.currentBrand = arrBrand;
            $ctrl.currentBrandName = selectedBrand.brandName;
        };

        $ctrl.seperateYearFromNamePlate = () => {
            const filteredCombinedNamePlatesYears = $ctrl.combinedNamePlatesYears.find(element => element.fullNamePlate === $ctrl.nameplateYear);
            $ctrl.year = filteredCombinedNamePlatesYears.nameplateYear;
            $ctrl.nameplate = filteredCombinedNamePlatesYears.nameplateName;
            $ctrl.nameplateCode = filteredCombinedNamePlatesYears.nameplateCode;
        };

        $ctrl.dealersLoading = () => {
            $('.C_CAD-form-dealerLocator-loader').show();
        };

        $ctrl.dealersLoaded = () => {
            $('.C_CAD-form-dealerLocator-loader').hide();
        };

        $ctrl.findDealers = (location) => {
            location = $ctrl.location;
            let radius = $ctrl.brandCode === 'alfaromeo' ? 10000 : 100;
            $ctrl.intNbOfDealersSelected = 0;
            $ctrl.dealerDisabled = false;
            $ctrl.mobileMaxDealers = $ctrl.mobileInitialMaxDealers;

            $ctrl.dealersLoading();
            return dealerLocator.findDealers($ctrl.apiUrl, location, $ctrl.brandCode, true, radius, false, $ctrl.nameplateCode, $ctrl.year, $ctrl.modelYearId).then((r) => {
                if (r.manualPostalCode !== undefined) {
                    r.userZipCode = r.manualPostalCode;
                }
                if (r.dealers.length) {
                    $ctrl.dealers = r.dealers;
                    $ctrl.dealersLoaded();
                } else {
                    $ctrl.dealerError = 'No dealers found, please update your location';
                }
            });
        };

        $ctrl.fetchASingleDealer = () => {
            $http.get(`/data/dealers/${$ctrl.brandCode}/${$ctrl.dealer}`).then((result) => {
                $ctrl.dealers = [[result.data][0]];
                $ctrl.dealersLoaded();
            });
        };

        $ctrl.fetchVinDetails = (vin) => {
            $http.get(`/data/inventories/${$ctrl.brandCode}/vehicle/offer/${vin}?language=${$ctrl.language}`).then((result) => {
                let sniVehicle = result.data;
                let offer = sniVehicle.offer;
                sniVehicle.vin = vin;
                $ctrl.brand = $ctrl.brands.filter((element) => element.brandCode === offer.brand)[0].brandName;
                $ctrl.brandCode = offer.brand;
                $ctrl.dealer = sniVehicle.dealerCode;
                $ctrl.acode = offer.acode;
                $ctrl.trimGroup = offer.trimGroup[$ctrl.language];
                $ctrl.modelYearId = offer.modelYearId;
                $ctrl.year = offer.modelYear;
                $ctrl.nameplateCode = offer.nameplatecode;
                if (sniVehicle.irisJellyUrl) {
                    sniVehicle.jellyUrl = sniVehicle.irisJellyUrl;
                } else if (sniVehicle.jellies) {
                    sniVehicle.jellyUrl = $ctrl.legacyRootURl + sniVehicle.jellies[0].jellies[0].path
                } else {
                    $ctrl.getVehicleJelly($ctrl.brandCode, $ctrl.nameplateCode, $ctrl.year, $ctrl.modelYearId).then(result => {
                        sniVehicle.jellyUrl = $ctrl.jellyUrl;
                    });
                }
                if ($ctrl.language === 'en') {
                    $ctrl.nameplateYear = `${$ctrl.year} ${offer.nameplate.en}`;
                } else {
                    $ctrl.nameplateYear = `${offer.nameplate.fr} ${$ctrl.year}`;
                }
                $http.get(`/data/dealers/${$ctrl.brandCode}/${sniVehicle.dealerCode}`).then((r) => {
                    sniVehicle.dealerData = r.data;
                    $ctrl.sniVehicles.push(sniVehicle);
                }).catch(reason => {
                    console.error('Dealer call failed : ', reason);
                });
            }).catch(reason => {
                console.error('Vin call failed : ', reason);
            });

        };

        $ctrl.setLocation = (data, setCenter = false) => {
            // Load data on map
            $ctrl.findDealers(data);
        };

        $ctrl.shortenDistanceFloat = (fltDistance) => {
            return Math.floor(fltDistance);
        };

        $ctrl.selectDealer = (dealer, zipCode) => {
            let selectedDealer = $ctrl.dealers.filter((element) => element.code === dealer);
            selectedDealer = selectedDealer.filter((element) => element.zipPostal === zipCode);

            if (!selectedDealer[0].selected) {
                selectedDealer[0].selected = true;
                $ctrl.intNbOfDealersSelected++;
                $('.C_CAD-form-dealerLocator-dealer-card').removeClass('ng-invalid ng-touched');
                $('.C_CAD-errors-dealerIds').addClass('-invisible');
            } else {
                selectedDealer[0].selected = false;
                $ctrl.intNbOfDealersSelected--;
            }

            $ctrl.resetAppointment();
            $ctrl.dealerDisabled = $ctrl.intNbOfDealersSelected === $ctrl.maxAmountOfDealers;
            $ctrl.selectedDealers = $ctrl.dealers.filter((element) => element.selected === true);
        };

        $ctrl.nextSlide = () => {
            if ($ctrl.sniVehicles.length > 1) {
                if ($ctrl.activeSlide < ($ctrl.sniVehicles.length - 1)) {
                    $ctrl.activeSlide++;
                } else {
                    $ctrl.activeSlide = 0;
                }
                $ctrl.previousSlideActivated = false;
                $ctrl.specificSlideActivated = false;
                $ctrl.nextSlideActivated = true;
            }
        };

        $ctrl.fieldChanged = () => {
            $ctrl.checkIfSubmitLeadReady();
        };

        $ctrl.checkIfSubmitLeadReady = () => {
            if ($ctrl.formErrors.email) {
                $('#email').addClass('ng-invalid ng-touched dealer-form-error').next('.C_CAD-errors').removeClass('ng-hide');
            }

            let submitLeadReady = $ctrl.brandCode && $ctrl.nameplateYear && $ctrl.firstName && $ctrl.lastName && $ctrl.postalCode && ($scope.contactMethod === "EMAIL" ? $ctrl.email : $ctrl.phone);

            if (submitLeadReady) {
                $rootScope.$broadcast('contact-a-dealer.field-changed.and-ready', true);
            } else {
                $rootScope.$broadcast('contact-a-dealer.field-changed.and-ready', false);
            }
        };

        $ctrl.previousSlide = () => {
            if ($ctrl.sniVehicles.length > 1) {
                if ($ctrl.activeSlide > 0) {
                    $ctrl.activeSlide--;
                } else {
                    $ctrl.activeSlide = $ctrl.sniVehicles.length - 1;
                }
                $ctrl.nextSlideActivated = false;
                $ctrl.specificSlideActivated = false;
                $ctrl.previousSlideActivated = true;
            }
        };

        $ctrl.specificSlide = (argIndex) => {
            if ($ctrl.sniVehicles.length > 1) {
                $ctrl.activeSlide = argIndex;
                $ctrl.nextSlideActivated = false;
                $ctrl.specificSlideActivated = false;
                $ctrl.specificSlideActivated = true;
            }
        };

        $ctrl.incrementDealers = () => {
            let max = parseInt($ctrl.mobileMaxDealers);
            if (max < $ctrl.dealers.length) {
                max += parseInt($ctrl.mobileDealerIncrement);
                $ctrl.mobileMaxDealers = max;
            }
        };

        $ctrl.animateVehicleSelector = (prevField) => {
            let current = '.C_CAD-form-' + $ctrl.visibleSelectField;
            $(current).addClass('animated fadeIn');
        };

        $ctrl.isNumericInput = (event) => {
            const key = event.keyCode;
            return ((key >= 48 && key <= 57) || // Allow Number line
                (key >= 96 && key <= 105) // Allow NumPad
            );
        };

        $ctrl.isModifierKey = (event) => {
            const key = event.keyCode;
            return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
                (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
                (key > 36 && key < 41) || // Allow left, up, right, down
                (
                    // Allow Ctrl/Command + A,C,V,X,Z
                    (event.ctrlKey === true || event.metaKey === true) &&
                    (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
                )
        };

        $ctrl.enforceFormat = (event) => {
            if (!$ctrl.isNumericInput(event) && !$ctrl.isModifierKey(event)) {
                event.preventDefault();
            }
        };

        $ctrl.formatPhoneNumber = (event) => {
            if ($ctrl.isModifierKey(event)) {
                return;
            }

            let target = event.target;
            const input = event.target.value.replace(/\D/g, '').substring(0, 10);
            const zip = input.substring(0, 3);
            const middle = input.substring(3, 6);
            const last = input.substring(6, 10);

            if (input.length > 6) {
                target.value = `(${zip}) ${middle}-${last}`;
            } else if (input.length > 3) {
                target.value = `(${zip}) ${middle}`;
            } else if (input.length > 0) {
                target.value = `(${zip}`;
            }
        };

        $ctrl.formatNameplateName = fullNameplateNameAndYear => {
            return fullNameplateNameAndYear.replace(/(<([^>]+)>)/ig, '')
                .replace('&reg;', '®')
                .replace('MD', 'ᴹᴰ')
                .replaceAll('&nbsp;', ' ');
        };

        $ctrl.checkIfNameplateIncludesYearOrBrand = (nameplate, yearOrBrand) => {
            return nameplate.includes(yearOrBrand);
        };

        // function for error messages
        $ctrl.showErrorMsgs = () => {
            const formErrorsArray = $(".dealer-form-error");

            if (formErrorsArray.length > 0) {
                if ($ctrl.language === "fr") {
                    if (formErrorsArray.length === 1) {
                        $(".dealerFormErrors").append(`<p>Il y a ${formErrorsArray.length} erreur dans le formulaire présentement. Veuillez la corriger afin de continuer.</p>`);
                    } else {
                        $(".dealerFormErrors").append(`<p>Il y a ${formErrorsArray.length} erreurs dans le formulaire présentement. Veuillez les corriger afin de continuer.</p>`);
                    }
                } else {
                    if (formErrorsArray.length === 1) {
                        $(".dealerFormErrors").append(`<p>There is ${formErrorsArray.length} error in your form. Please correct it before submitting.</p>`)
                    } else {
                        $(".dealerFormErrors").append(`<p>There are ${formErrorsArray.length} errors in your form. Please correct them before submitting.</p>`)

                    }
                }
                $ctrl.errorMsgVisible = true;
            } else {
                $ctrl.errorMsgVisible = false;
            }
            $timeout(function () {
                $scope.$apply();
                $(".dealerFormErrors").focus();
            }, 0);
        }

        $ctrl.submitLeadAjax = () => {
            $scope.submitted = true;

            if($ctrl.leadId === "") {
                $ctrl.postLead();
            } else if ($ctrl.existAppointment) {
                $ctrl.confirmAppointment();
            }

            $(".dealerFormErrors").html('');
            $('input, .C_CAD-errors-dealerIds').removeClass('dealer-form-error');
            $ctrl.errorMsgVisible = false;

            $ctrl.showErrorMsgs();

            if (!$scope.leadFormName.$valid) {
                if ($ctrl.brandCode === "alfaromeo") {
                    $("html, body").animate({scrollTop: $('#lead-form-id').offset().top - $('header').height()}, 300);
                } else {
                    $("html, body").animate({scrollTop: $('#lead-form-id').offset().top}, 300);
                }
            }

            if (!$ctrl.hasEmptyValue && !$ctrl.hasInvalidValue) {
                $ctrl.formIsLoading = true;
                $("#submit-button").addClass('btn-spinner');
            }
        };

        $ctrl.generateAltTag = (pModelYear, pBrandName, pNamepateName, pTrimGroup) => {
            let brandName = window.FCA_SITES_CONFIG.name;
            let altText = '';

            altText = pModelYear + ' ' + pBrandName + ' ' + pNamepateName + ' ' + pTrimGroup;
            if (window.FCA_SITES_CONFIG.language === 'fr') {
                altText = pBrandName + ' ' + pNamepateName + ' ' + pModelYear + ' ' + pTrimGroup;
            }
            return $ctrl.formatNameplateName(altText);
        };

        $ctrl.focusOnSelect = () => {
            $timeout(() => {
                const whatWeFocusOn = document.querySelectorAll('.C_CAD-form-step-vehicleSelector select')[0];

                if (whatWeFocusOn) {
                    whatWeFocusOn.focus();
                }
            });
        };

        $ctrl.changeVehicleChoice = (fieldToShow, prevField, isClickEvent) => {
            $ctrl.changeVisibleSelectField(fieldToShow, isClickEvent);
            $ctrl.clearJellyUrl();
            $ctrl.animateVehicleSelector(prevField);
            $ctrl.focusOnSelect();
        };

        $ctrl.dealerIsDigital = (dealer) => {
            return dealer.isDigital;
        };

        /**
         *
         * @returns {boolean} true if one dealer is selected
         */
        $ctrl.oneDealerSelected = () => {
            let oneIsSelected = false;
            if($ctrl.intNbOfDealersSelected === 1 && $ctrl.dealerIsDigital($ctrl.selectedDealers[0])) {
                oneIsSelected = true;
            }
          if ($ctrl.dealer !== "") {
            let dealersFiltered = $ctrl.dealers.filter(
                (element) => element.code === $ctrl.dealer);
            $ctrl.selectedDealers[0] = dealersFiltered[0];
            oneIsSelected = true;
            $ctrl.isMultipleDealersMode = false;
          }
          return oneIsSelected;
        };

        $rootScope.$on('fca-sales-appointment:appointment-is-selected',
            () => {
                $ctrl.existAppointment = true;
            }
        );

        $rootScope.$on('fca-sales-appointment:appointment-is-canceled',
            () => {
                $ctrl.existAppointment = false;
            }
        );

        /**
         *  get the date and time values  send by sales appointment component
         */
        $ctrl.selectAppointment = () => {
            let appointmentObject = fcaSalesAppointmentService.getFromStorage();
            $ctrl.appointmentDate = appointmentObject.date;
            $ctrl.appointmentTime = appointmentObject.time;

            $ctrl.appointmentErrorMsg = null;
            $(".dealerFormErrors").html('');
            $ctrl.errorMsgVisible = false;
        };

        /**
         *  alert the sales appointment component to post the appointment
         */
        $ctrl.confirmAppointment = () => {

            let dealerCode = '';
            if ($ctrl.selectedDealers[0] !== undefined) {
                dealerCode = $ctrl.selectedDealers[0].code;
            } else if ($ctrl.dealer !== null && $ctrl.dealer !== '') {
                dealerCode = $ctrl.dealer;
            }

            $rootScope.$broadcast('fca-contact-a-dealer:appointment-confirmation', {
                brandCode: $ctrl.brandCode,
                dealerCode: dealerCode,
                leadId: $ctrl.leadId,
                date: $ctrl.appointmentDate,
                time: $ctrl.appointmentTime,
                testDrive: $ctrl.testDriveBooked()
            });

            $(".dealerFormErrors").html('');
            $ctrl.errorMsgVisible = false;
        };

        $ctrl.resetAppointment = () => {
            $ctrl.appointmentDate = '';
            $ctrl.appointmentTime = '';
        };

        $ctrl.testDriveBooked = () => {
            return $ctrl.reason === 'Book_a_Test_Drive';
        };

        $ctrl.goThankYou = () => {
            let dealerCode = '';

            if ($ctrl.dealer !== null && $ctrl.dealer !== '') {
                dealerCode = $ctrl.dealer;
            } else if ($ctrl.selectedDealers[0] !== undefined) {
                dealerCode = $ctrl.selectedDealers[0].code;
            }

            if ($ctrl.buildAndPriceMode) {
                $ctrl.contactADealerThankYouUrl = 'custom-build-dealer-thank-you';
                if ($ctrl.language === 'fr') {
                    $ctrl.contactADealerThankYouUrl = 'custom-build-concessionnaire-merci';
                }
            }

            let url;
            if ($ctrl.nameplateCode !== "") {
                url = $ctrl.queryParams !== "" ?
                    `${$ctrl.contactADealerThankYouUrl}${$ctrl.queryParams}&nameplateCode=${$ctrl.nameplateCode}&year=${$ctrl.year}&name=${encodeURIComponent($ctrl.nameplateYear)}&appointmentConfirmed=${encodeURIComponent($ctrl.existAppointment)}&dealerCode=${encodeURIComponent(dealerCode)}` :
                    `${$ctrl.contactADealerThankYouUrl}?nameplateCode=${$ctrl.nameplateCode}&year=${$ctrl.year}&name=${encodeURIComponent($ctrl.nameplateYear)}&appointmentConfirmed=${encodeURIComponent($ctrl.existAppointment)}&dealerCode=${encodeURIComponent(dealerCode)}`;
            } else {
                url = $ctrl.queryParams !== "" ?
                    `${$ctrl.contactADealerThankYouUrl}${$ctrl.queryParams}&appointmentConfirmed=${encodeURIComponent($ctrl.existAppointment)}&dealerCode=${encodeURIComponent(dealerCode)}` :
                    `${$ctrl.contactADealerThankYouUrl}?appointmentConfirmed=${encodeURIComponent($ctrl.existAppointment)}&dealerCode=${encodeURIComponent(dealerCode)}`
            }
            $window.location.href = url;
        };

        $ctrl.postLead = () => {
            $(".dealerFormErrors").html('');
            $('input, .C_CAD-errors-dealerIds').removeClass('dealer-form-error');
            $ctrl.errorMsgVisible = false;
            let body = $("#lead-form-id").serializeArray();
            let form = {};

            if ($ctrl.dealer !== null && $ctrl.dealer !== '') {
              dealerCode = $ctrl.dealer;
            } else if ($ctrl.selectedDealers[0] !== undefined) {
              dealerCode = $ctrl.selectedDealers[0].code;
            }

            let url;
            if ($ctrl.nameplateCode !== "") {
              url = $ctrl.queryParams !== "" ?
                  `${$ctrl.contactADealerThankYouUrl}${$ctrl.queryParams}&nameplateCode=${$ctrl.nameplateCode}&year=${$ctrl.year}&name=${encodeURIComponent(
                      $ctrl.nameplateYear)}&appointmentConfirmed=${encodeURIComponent(
                      $ctrl.existAppointment)}&dealerCode=${encodeURIComponent(
                      dealerCode)}` :
                  `${$ctrl.contactADealerThankYouUrl}?nameplateCode=${$ctrl.nameplateCode}&year=${$ctrl.year}&name=${encodeURIComponent(
                      $ctrl.nameplateYear)}&appointmentConfirmed=${encodeURIComponent(
                      $ctrl.existAppointment)}&dealerCode=${encodeURIComponent(
                      dealerCode)}`;
            } else {
              url = $ctrl.queryParams !== "" ?
                  `${$ctrl.contactADealerThankYouUrl}${$ctrl.queryParams}&appointmentConfirmed=${encodeURIComponent(
                      $ctrl.existAppointment)}&dealerCode=${encodeURIComponent(
                      dealerCode)}` :
                  `${$ctrl.contactADealerThankYouUrl}?appointmentConfirmed=${encodeURIComponent(
                      $ctrl.existAppointment)}&dealerCode=${encodeURIComponent(
                      dealerCode)}`
            }
            $window.location.href = url;
        };

        $ctrl.fieldHasEmptyValue = (field) => {
            if (field.match(/^ *$/) !== null || field === '') {
                return true;
            } else {
                return false;
            }
        }

        $ctrl.fieldHasInvalidValue = (field) => {
            if (!field.match(/^[a-zA-ZÀ-ÿ-. ']*$/)) {
                return true;
            } else {
                return false;
            }
        }

        $ctrl.postLead = () => {
            $(".dealerFormErrors").html('');
            $('input, .C_CAD-errors-dealerIds').removeClass('dealer-form-error');
            $ctrl.errorMsgVisible = false;
            let body = $("#lead-form-id").serializeArray();
            let form = {};

            // format comments field
            let commentsEn = "";
            let commentsFr = "";
            let appointmentString = $ctrl.appointmentDate + " " + $ctrl.appointmentTime;
            let dateTimeString = "";
            let testDrive = "";

            // building obj for campaign tracker
            $(body).each(function (i, field) {
                if (field.name === "contactInterest") {
                    if ($ctrl.language == 'en') {
                        if(field.value === "Book_a_Test_Drive") {
                            testDrive = field.value === "Book_a_Test_Drive"
                                ? "The customer has requested a test drive."
                                : "The customer is not interested in a test drive.";
                            commentsEn += testDrive+ "\n";
                        }
                        if ($ctrl.existAppointment) {
                            commentsEn += "The customer has requested an appointment for " + appointmentString + "\n";
                        }
                    } else {
                        if(field.value === "Book_a_Test_Drive") {
                            testDrive = field.value === "Book_a_Test_Drive"
                                ? "Le client est interessé par un essai routier."
                                : "Le client n'est pas interessé par un essai routier.";
                            commentsFr += testDrive + "\n";
                        }
                        if ($ctrl.existAppointment) {
                            commentsFr += "Le client a reservé un rendez-vous pour " + appointmentString + "\n";
                        }
                    }
                }

                if (field.name === "customerComments") {
                    if(field.value !== "") {
                        if ($ctrl.language === 'en') {
                            commentsEn += "The customer's comments : " + field.value + "\n";
                        } else {
                            commentsFr += "Les commentaires du client : " + field.value + "\n";
                        }
                    }
                    field.value = $ctrl.language ==='en' ? commentsEn : commentsFr;
                }

                if (field.name === "leadType") {
                    if ($ctrl.existAppointment) {
                        field.value = "appointment";
                    }
                }

                if (form[field.name]) {
                    if (form[field.name] instanceof Array) {
                        form[field.name].push(field.value);
                    } else {
                        const tempValue = form[field.name];
                        form[field.name] = [];
                        form[field.name].push(tempValue);
                        form[field.name].push(field.value);
                    }
                } else {
                    form[field.name] = field.value;
                }
            });

            let dealerIds = [];
            body = body.filter(element => {
                if (element.name === 'dealerIds') {
                    if (!dealerIds.includes(element.value)) {
                        dealerIds.push(element.value);
                    } else {
                        return false;
                    }
                }

                if (element.name === 'firstname') {
                    if ($ctrl.fieldHasEmptyValue(element.value)) {
                        $ctrl.firstNameHasEmptyValue = true;
                    } else {
                        $ctrl.firstNameHasEmptyValue = false;
                        if ($ctrl.fieldHasInvalidValue(element.value)) {
                            $ctrl.firstNameHasInvalidValue = true;
                            $scope.leadFormName.firstname.$error.required = true;
                        } else {
                            $ctrl.firstNameHasInvalidValue = false;
                        }
                    }
                }

                if (element.name === 'lastname') {
                    if ($ctrl.fieldHasEmptyValue(element.value)) {
                        $ctrl.lastNameHasEmptyValue = true;
                    } else {
                        $ctrl.lastNameHasEmptyValue = false;
                        if ($ctrl.fieldHasInvalidValue(element.value)) {
                            $ctrl.lastNameHasInvalidValue = true;
                            $scope.leadFormName.lastname.$error.required = true;
                        } else {
                            $ctrl.lastNameHasInvalidValue = false;
                        }
                    }
                }

                return true;
            });

            body = $.param(body);

            $ctrl.hasEmptyValue = $ctrl.firstNameHasEmptyValue || $ctrl.lastNameHasEmptyValue;

            $ctrl.hasInvalidValue = $ctrl.firstNameHasInvalidValue || $ctrl.lastNameHasInvalidValue;

            if(!$ctrl.hasEmptyValue && !$ctrl.hasInvalidValue) {
                $.post(`/api/lead/${$ctrl.language}/brand/${$ctrl.currentBrandCode}`, body, function (data) {
                    if (data.success) {

                        let campaignForm = {};

                        campaignForm["isAppsuite"] = 1;
                        if (form.leadType) {
                            campaignForm["leadType"] = form.leadType;
                        }
                        if (form.postType) {
                            campaignForm["postType"] = form.postType;
                        }
                        if (form.nameplateDescription) {
                            campaignForm["namePlateSelected"] = form.nameplateDescription;
                        }
                        if (form.acode) {
                            campaignForm["bpAcode"] = form.acode;
                        }
                        if (form.firstname) {
                            campaignForm["formFirstName"] = form.firstname;
                        }
                        if (form.lastname) {
                            campaignForm["formLastName"] = form.lastname;
                        }
                        if (form.email) {
                            campaignForm["formEmail"] = form.email;
                        }
                        if (form.postalCode) {
                            campaignForm["formPostalCode"] = form.postalCode;
                        }
                        if (form.dealerIds) {
                            campaignForm["retailerIds"] = form.dealerIds;
                        }
                        if (form.phone) {
                            campaignForm["formPhone"] = form.phone;
                        }

                        $.post(`/momentum/campaign/campaignTracker.php`, campaignForm);

                        if($ctrl.existAppointment) {
                            $ctrl.leadId = data.leadResponse.leadId;
                            $ctrl.confirmAppointment();

                        } else {
                            if(!$ctrl.isTradeIn) {
                                $ctrl.goThankYou();
                            }
                        }
                    }

                    if (!data.success) {
                        $ctrl.formErrors = data.fieldErrors;
                        $ctrl.formIsLoading = false;
                        $("#submit-button").removeClass('btn-spinner');
                        if ($ctrl.formErrors.firstname || form.firstname.match(/^ *$/) !== null || $ctrl.fieldHasInvalidValue(form.firstname)) {
                            $('#firstname').addClass('ng-invalid ng-touched dealer-form-error').next('.C_CAD-errors').removeClass('ng-hide');
                        }
                        if ($ctrl.formErrors.lastname || form.lastname.match(/^ *$/) !== null || $ctrl.fieldHasInvalidValue(form.lastname)) {
                            $('#lastname').addClass('ng-invalid ng-touched dealer-form-error').next('.C_CAD-errors').removeClass('ng-hide');
                        }
                        if ($ctrl.formErrors.email) {
                            $('#email').addClass('ng-invalid ng-touched dealer-form-error').next('.C_CAD-errors').removeClass('ng-hide');
                        }
                        if ($ctrl.formErrors.phone) {
                            $('#phone').addClass('ng-invalid ng-tpostalCodeouched dealer-form-error').next('.C_CAD-errors').removeClass('ng-hide');
                        }
                        if ($ctrl.formErrors.postalCode) {
                            $('#postalCode').addClass('ng-invalid ng-touched dealer-form-error').next('.C_CAD-errors').removeClass('ng-hide');
                        }
                        if ($ctrl.formErrors.dealerIds) {
                            $('.C_CAD-form-dealerLocator-dealer-card').addClass('ng-invalid ng-touched');
                            $('.C_CAD-errors-dealerIds').removeClass('-invisible').addClass('dealer-form-error');
                        }
                        $ctrl.showErrorMsgs();
                        if ($ctrl.brandCode === "alfaromeo") {
                            $("html, body").animate({scrollTop: $('#lead-form-id').offset().top - $('header').height()}, 300);
                        } else {
                            $("html, body").animate({scrollTop: $('#lead-form-id').offset().top}, 300);
                        }
                    } else {
                        $ctrl.formIsLoading = false;
                        if ($ctrl.getNotifiedOnSucceed) {
                            const targetBrandUrl = FCA_SITES_CONFIG.brandSiteUrls[$ctrl.currentBrandCode] || "";
                            if (!targetBrandUrl && FCA_SITES_CONFIG.environment !== 'prod') {
                                console.warn(`'${$ctrl.currentBrandCode}' is not part of brandSiteUrls`);
                            }
                            let buildAndPriceUrl =
                                `${targetBrandUrl}/` +
                                `${document.documentElement.lang}/build-and-price/` +
                                `${$ctrl.currentBrandCode}/` +
                                `${$ctrl.nameplateCode}/` +
                                `${$ctrl.year}/` +
                                `${$ctrl.modelYearId}/${$ctrl.acode}`;

                            let inventoryLangVar = document.documentElement.lang === "fr" ? "/vehicules-neufs/recherche/" : "/new-inventory/search/";
                            let inventoryUrl =
                                `${targetBrandUrl}/` +
                                `${document.documentElement.lang}` + inventoryLangVar +
                                `${$ctrl.currentBrandCode}/` +
                                `${$ctrl.nameplateCode}` +
                                `#activeTab=cash&year=${$ctrl.year}` +
                                `&modelYearId=${$ctrl.modelYearId}` +
                                `&trimPkg=${$ctrl.trimGroup}`;

                            var selectedData = {
                                contactMethod: form.contactMethod,
                                firstname: form.firstname,
                                lastname: form.lastname,
                                postalCode: form.postalCode,
                                email: form.email,
                                brandCode: $ctrl.currentBrandCode,
                                nameplateCode: $ctrl.nameplateCode,
                                year: $ctrl.year,
                                trimDescription: $ctrl.trimGroup,
                                trimGroupCode: $ctrl.trimGroupCode,
                                modelYearId: $ctrl.modelYearId,
                                jellyPath: $ctrl.jellyUrl,
                                dealerCode: form.dealerIds[0],
                            };

                            $rootScope.$broadcast(
                                'contact-a-dealer.submit-to-dealer-succeed', {
                                    buildAndPrice: buildAndPriceUrl,
                                    inventory: inventoryUrl,
                                    selectedData: selectedData
                                }
                            );
                        }
                    }
                });
            }
        };

        $ctrl.analyticsOnBrandSelect = (selectedBrand) => {
            gtmAnalytics.trackEvent('event', {
                category: 'App-Suite-contact-a-dealer',
                label: 'dropdownSelect-' + selectedBrand
            });
        };

		$ctrl.setElectricVehicleState = () => {
			// @TODO Need to be activate later
			// contactADealerService.getElectricVehicleState($ctrl.brandCode, $ctrl.modelYearId).then((isElectricVehicle) => {
			// 	$ctrl.isElectricVehicle = isElectricVehicle;
			// });
		};

        $rootScope.$on('fca-contact-a-dealer:appointment-not-confirmed', (event, data) => {
            let response = data.response;

            if(!response.success && response.message) {
                $timeout(() => {
                    $ctrl.appointmentErrorMsg = response.message;
                });
            }
        });

        $rootScope.$on('fca-contact-a-dealer:appointment-is-confirmed',
            () => {
               $ctrl.goThankYou();
            }
        );

        $rootScope.$on('form-dealer-selector:appointment-dealer-selected',
            (evt, args) => {
                $ctrl.dealer = args.dealer;
            }
        );
    }
})();
