(function () {
	angular
		.module('fca.brandApp')
		.directive('fcaStickyToolbar', fcaStickyToolBar);

	function fcaStickyToolBar() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: '$stickyToolBarCtrl',
			controller: FcaStickyToolBarCtrl
		};

		function FcaStickyToolBarCtrl($rootScope, $timeout, $element, $window, functionExecutionControl) {
			'ngInject';
			let $ctrl = this;
			let dialogRef;
			let firstLimiter = null;
			let lastLimiter = null;
			let enableVisibilityCheck = true;
			let lastScrollY = 0;

			const KEY_ESC = 27;
			const tabletBreakpoint = 1024;

			const onKeyDown = (event) => {
				// ESCAPE key pressed
				if( event.keyCode === KEY_ESC ) {
					let openChildPartials = $element.find('.partial-overlay-wrapper.drawer-open');

					if($ctrl.mobileNavIsOpen && openChildPartials.length < 1) {
						$ctrl.toggleMobileNav();
						$window.document.removeEventListener('keydown', onKeyDown);
					}
				}
			};

			$ctrl.isVisible = false;
			$ctrl.appearedOnce = false;
			$ctrl.mobileNavIsOpen = false;
			$ctrl.stayVisible = false;
			$ctrl.chatIsDisplayed = false;

			$ctrl.$onInit = () => {
				$ctrl.defineLimiters();
				$ctrl.bindResize();
				$ctrl.bindScrolling();

				$rootScope.$on('partial:invoke-partial', () => {
					if($ctrl.isVisible) {
						$ctrl.stayVisible = true;
					}
				});

				$rootScope.$on('partial:pause-scrolling', () => {
					// This prevents showing the sticky tool bar on modal close when it should be hidden
					enableVisibilityCheck = false;
				});

				$rootScope.$on('partial:close-partial', () => {
					$timeout(() => {
						enableVisibilityCheck = true;
					});

					if($ctrl.stayVisible) {
						$timeout(() => {
							$ctrl.stayVisible = false;
						}, 2500);
					}
				});

				$rootScope.$on('nearestDealersWithChatObtained', (evt, args) => {
					$ctrl.chatIsDisplayed = true;
				});

				$ctrl.checkSessionStorage();
			};

			$ctrl.setSessionStorage = () => {
				window.FcaCookieChecker.addSessionStorage('stickyToolBarAppearedOnce', 'true');
			};

			$ctrl.checkSessionStorage = () => {
				$ctrl.appearedOnce = sessionStorage.getItem('stickyToolBarAppearedOnce') != null;
			};

			$ctrl.bindScrolling = () => {
				window.addEventListener('scroll', (event) => {
					functionExecutionControl.debounce($ctrl.visibilityCheck(), 200);
				});
			};

			$ctrl.bindResize = () => {
				window.addEventListener('resize', (event) => {
					functionExecutionControl.debounce($ctrl.handleResize(), 400);
				});
			};

			$ctrl.visibilityCheck = () => {
				let oldValue = $ctrl.isVisible;
				let windowHeight = $(window).height();
				let windowWidth = $(window).width();
				let topLimit = 0;
				let bottomLimit = 0;
				let topOffset = windowHeight;
				let stickyNav = document.querySelector('.VLP2021-navigation__container.is-stuck');
				let desktopOffset = 60;
				let stickyContainer = $element.find('[data-sticky-container]')[0];

				if(stickyNav) {
					topOffset = stickyNav.offsetHeight;
				}

				if(firstLimiter) {
					if(windowWidth < 768 ) {
						topLimit = (firstLimiter.getBoundingClientRect().bottom - topOffset);
					} else {
						topLimit = (firstLimiter.getBoundingClientRect().bottom + desktopOffset);
					}
				}

				if(lastLimiter) {
					if(windowWidth < 768 ) {
						bottomLimit = (lastLimiter.getBoundingClientRect().top);
					} else {
						bottomLimit = (lastLimiter.getBoundingClientRect().top - desktopOffset);
					}
				}

				if(enableVisibilityCheck) {
					$timeout(() => {
						if(windowWidth < 768 ) {
							$ctrl.isVisible = (topLimit <= 0 && bottomLimit > windowHeight);
						} else {
							$ctrl.isVisible = (topLimit <= stickyContainer.getBoundingClientRect().top && bottomLimit > stickyContainer.getBoundingClientRect().bottom);
						}

						if(oldValue !== $ctrl.isVisible) {
							if($ctrl.isVisible) {
								$('[data-fca-shopping-tools-bar]').addClass('hidden-toolbar');
							} else {
								$('[data-fca-shopping-tools-bar]').removeClass('hidden-toolbar');
							}
						}

						if($ctrl.isVisible && !$ctrl.appearedOnce) {
							$timeout(() => {
								$ctrl.setSessionStorage();
								$ctrl.checkSessionStorage();
							}, 2500);
						}
					});
				}
			};

			$ctrl.defineLimiters = () => {
				let limitSelectors = document.querySelectorAll('[data-fca-shopping-tools-bar]');
				let footer = document.querySelector('footer.footer');

				if(limitSelectors) {
					lastLimiter = limitSelectors[limitSelectors.length - 1];

					if(limitSelectors.length > 1) {
						firstLimiter = limitSelectors[0];
					}
				} else if(footer) {
					lastLimiter = footer;
				}

				$ctrl.visibilityCheck();
			}

			$ctrl.pauseScrolling = () => {
				$rootScope.$emit('tool-bar:pause-scrolling');
				$('body').addClass('overflow-hidden');
				$('html').addClass('overflow-hidden');
			}

			$ctrl.resumeScrolling = () => {
				$rootScope.$emit('tool-bar:resume-scrolling');
				$('body').removeClass('overflow-hidden');
				$('html').removeClass('overflow-hidden');
			}

			$ctrl.handleResize = () => {
				$ctrl.visibilityCheck();

				if($ctrl.mobileNavIsOpen && $(window).width() >= tabletBreakpoint) {
					$timeout(() => {
						$ctrl.toggleMobileNav();
					});
				}
			};

			$ctrl.handleOverlayClick = () => {
				if($ctrl.mobileNavIsOpen) {
					$ctrl.toggleMobileNav();
				}
			}

			$ctrl.toggleMobileNav = () => {
				$ctrl.mobileNavIsOpen = !$ctrl.mobileNavIsOpen;

				if($ctrl.mobileNavIsOpen) {
					$ctrl.pauseScrolling();
					$window.document.addEventListener('keydown', onKeyDown);
				} else {
					$ctrl.resumeScrolling();
					$window.document.removeEventListener('keydown', onKeyDown);
				}
			};

			$ctrl.scrollToTop = () => {
				$('html, body').animate({
					scrollTop: 0
				}, 400);
			}
		}
	}
})();
