/**
 * Directive to manage the Animated Hero Banner
 * - Manage Analytics
 * - Manage modal opening (todo)
 */
(function() {
    angular
        .module('fca.commonDirectives')
        .directive('fcaAnimatedHeroBanner', fcaAnimatedHeroBanner);

    function fcaAnimatedHeroBanner() {
       return {
            restrict: 'A',
            controllerAs: '$ctrl',
            controller: FcaAnimatedHeroController,
			scope: true,
            bindToController: {
                vehicleSelection: '@',
				vehicleProperties: '@',
                storageId: '@'
            }
        };

        function FcaAnimatedHeroController(gtmAnalytics) {
            'ngInject';
            const $ctrl = this;
            $ctrl.selectedVehicleInfo = null;
            $ctrl.animatedHeroElement = null; // ncl-animated-hero HTMLElement
			$ctrl.animatedHeroModal = null; // ncl-animated-hero-modal HTMLElement
            $ctrl.vehicleSelectorModalElement = null; //ncl-modal HTMLElement
            $ctrl.vehicleSelectionArray = [];
			$ctrl.vehiclePropertiesArray = [];

            $ctrl.$onInit = () => {

                $ctrl.animatedHeroElement = document.querySelector('ncl-animated-hero');
				$ctrl.animatedHeroModal = document.querySelector('.animated-hero-banner__selector-nameplate-modal');
				if($ctrl.animatedHeroModal) {
					$ctrl.animatedHeroModal.addEventListener("nclClose", manageCloseModalAnalytics);
				}

                try {
                    // init data from the params
                    if ($ctrl.vehicleSelection) $ctrl.vehicleSelectionArray = JSON.parse($ctrl.vehicleSelection);
                    if ($ctrl.vehicleProperties) $ctrl.vehiclePropertiesArray = JSON.parse($ctrl.vehicleProperties);
                } catch(e) {
                    Logger.error(e);
                }

                // Updates the URL parameters with the last vehicle of the Sling list if no param in the URL yet
                if(!hasNameplateParamsInURL() && $ctrl.vehicleSelectionArray && $ctrl.vehicleSelectionArray.length) {
                    $ctrl.selectedVehicleInfo = $ctrl.vehicleSelectionArray[$ctrl.vehicleSelectionArray.length - 1];
                    updateURL();
                } else if(hasNameplateParamsInURL()) {
                    const urlParams = new URLSearchParams(window.location.search);
                    $ctrl.selectedVehicleInfo = urlParams.get('nameplateId');
                }

                // Initial api call to update the page with the selected vehicle info
                emitNameplateChanges();

                if($ctrl.animatedHeroElement) {
                    // Listens for the title button click event to open the selector modal
                    $ctrl.animatedHeroElement.addEventListener('fca:animated-hero:click', event => {
                        manageSelectorButtonAnalytics(event);
                        $ctrl.vehicleSelectorModalElement = document.querySelector('ncl-modal.animated-hero-banner__selector-nameplate-modal');
                        if($ctrl.vehicleSelectorModalElement) $ctrl.vehicleSelectorModalElement.opened = true;
                        manageVehicleSelection();
                    })

                    $ctrl.animatedHeroElement.addEventListener('fca:animated-hero:vehicle-link:click', event => {
                        manageVehicleJellyAnalytics(event);
                    })

                    $ctrl.animatedHeroElement.addEventListener('fca:animated-hero:animation-complete', event => {
                        window.FcaCookieChecker.addSessionStorage($ctrl.storageId, true);
                    })
                }

            };

            function emitNameplateChanges() {
				const vehiculeInfos = getVehicleInfosFromString();
                window.PubSubManager.publish("EVENT.evTools.nameplate", vehiculeInfos);
				manageSelectModalAnalytics(vehiculeInfos.nameplateCode);
            }

            function getVehicleInfosFromString() {
                if(!$ctrl.selectedVehicleInfo) return;

				const vehicleInfoRegex = /[^/]+/g;
                const vehicleInfoArray = $ctrl.selectedVehicleInfo.match(vehicleInfoRegex);
				const vehicle = $ctrl.vehiclePropertiesArray.find(curr => curr.id === $ctrl.selectedVehicleInfo) || {};

				return Object.assign(vehicle, {
					nameplateCode: vehicleInfoArray[0],
                    modelYear: vehicleInfoArray[1],
                    modelYearId: vehicleInfoArray[2],
                    acode: vehicleInfoArray[3],
				});
            }

            function manageVehicleSelection() {
                if(!$ctrl.vehicleSelectorModalElement) return;

                const modalSelectButtonElement = document.getElementById('animated-hero-modal-select-button');
                if(modalSelectButtonElement) {
                    // Listens to the "select" button in the modal to update the selected vehicle
                    modalSelectButtonElement.addEventListener('nclClick', () => {
                        const selectedVehicleCard = document.querySelector('.fca-provincialize-block .vehicle-tray-carousel-card.slick-active');

						if (selectedVehicleCard) {
							$ctrl.selectedVehicleInfo = selectedVehicleCard.id;

							updateVehicleSelected();
							updateURL();
						}

                        if($ctrl.vehicleSelectorModalElement) {
							$ctrl.vehicleSelectorModalElement.opened = false;
						}

                    }, {
						once: true
					});
                }
            }

            function updateVehicleSelected() {
                emitNameplateChanges();
                updateAnimatedHeroSelectedVehicleTitle();
                updateAnimatedHeroSelectedVehicleJelly();
            }

            function updateAnimatedHeroSelectedVehicleTitle() {
                const selectorButtonElements = $ctrl.animatedHeroElement.shadowRoot.querySelectorAll('.animated-hero__selector-button-item');
                const selectorButtonContainerElement = $ctrl.animatedHeroElement.shadowRoot.querySelector('.animated-hero__selector-button-container');

                if(selectorButtonElements.length) {

                    selectorButtonElements.forEach((selectorButton) => {
                        const isNewSelectedVehicle = selectorButton.attributes.getNamedItem("id").value === $ctrl.selectedVehicleInfo;
                        selectorButton.disabled = !isNewSelectedVehicle;
                        selectorButton.style.opacity = isNewSelectedVehicle ? '1' : '0';
                        selectorButton.style.zIndex = isNewSelectedVehicle ? '10' : '-10';
                        selectorButton.style.visibility = isNewSelectedVehicle ? 'visible' : 'hidden';
                        if(selectorButtonContainerElement && isNewSelectedVehicle) selectorButtonContainerElement.style.height = `${selectorButton.offsetHeight}px`;
                });
                }
            }

            function updateAnimatedHeroSelectedVehicleJelly() {
                const jelliesElements = $ctrl.animatedHeroElement.shadowRoot.querySelectorAll('.jelly');
                jelliesElements.forEach((jelly) => {
                    const isNewSelectedVehicle = jelly.attributes.getNamedItem("id").value === $ctrl.selectedVehicleInfo;
                    jelly.style.opacity = isNewSelectedVehicle ? '1' : '0';
                    jelly.style.visibility = isNewSelectedVehicle ? 'visible' : 'hidden';
                });
            }

            function hasNameplateParamsInURL() {
                const urlParams = new URLSearchParams(window.location.search);
                return urlParams.has('nameplateId');
            }

            function updateURL() {
                let url = new URL(window.location.href);
                let params = new URLSearchParams(url.search);
                let vehicleInfos = getVehicleInfosFromString();
                updateURLParameter(params, 'nameplate', vehicleInfos.nameplateCode);
                updateURLParameter(params, 'year', vehicleInfos.modelYear);
                updateURLParameter(params, 'modelYearId', vehicleInfos.modelYearId);
                updateURLParameter(params, 'acode', vehicleInfos.acode);
                updateURLParameter(params, 'nameplateId', $ctrl.selectedVehicleInfo);
                url.search = params.toString();
                history.pushState(null, '', url.href);
            }

            function updateURLParameter(parameters, key, value) {
                if(parameters.has(key)) parameters.set(key, value);
                else parameters.append(key, value);
            }

            //#region ANALYTICS
            function manageSelectorButtonAnalytics(event) {
                sendGtmAnalytics(`hero-banner-cta-${event.detail.buttonName} - open`);
            }

            function manageVehicleJellyAnalytics(event) {
                sendGtmAnalytics(`hero-banner-nameplate-click-${event.detail.vehicleName}`);
            }
			function manageCloseModalAnalytics() {
				sendGtmAnalytics(`hero-banner-cta-modal - close`);
			}
			function manageSelectModalAnalytics(nameplate) {
				sendGtmAnalytics(`hero-banner-cta-modal-${nameplate} - select`);
			}


            function sendGtmAnalytics(label) {
                gtmAnalytics.trackEvent('event', {
                    category: 'App-Suite-' + window.FCA_SITES_CONFIG.pageCode,
                    label
                });
            }
            //#endregion

    }
}

})();
