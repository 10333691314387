(function () {
	angular
		.module('fca.miniBuildAndPrice.director')
		.component('directorMiniBp', {
			controller: directorMiniBp,
			controllerAs: '$ctrl',
			templateUrl: '/shared/fca-mini-build-and-price/director-mini-bp.html',
			bindings: {
				modelYearId: '@',
				acode: '@',
				brand: '@',
				nameplate: '@',
				year: '@',
				baseSniBuildUrl: '@',
				contactDealerDesktop: '@',
				contactDealerMobile: '@',
				findDealerDesktop: '@',
				findDealerMobile: '@',
				sniDesktop: '@',
				sniMobile: '@',
				alfaPlaceholderImage: '@',
				selectedNameplateName: '@',
				desktopBackgroundUrl: '@',
				iconModelsUrl: '@',
				iconPowerDesktopUrl: '@',
				iconPowerMobileUrl: '@',
				iconSpeedDesktopUrl: '@',
				iconSpeedMobileUrl: '@',
				iconTorqueDesktopUrl: '@',
				iconTorqueMobileUrl: '@',
				trimGroupJson: '@',
				vehicleBuildAndPriceUrl: '@',
				vehicleBaseUrl: '@',
				vehicleSearchNewInventoryUrl: '@',
				statsFound: '<',
				withBackground: '<',
				withModelName: '<',
				hidePricing: '<',
				hideBpCta: '<',
				hideSniCta: '<',
				background: '@',
				statisticStyle: '@',
				statsTorque: '@',
				statsPower: '@',
				statsAcceleration: '@',
				statsQuadrifoglioFound: '<',
				statsQuadrifoglioTorque: '@',
				statsQuadrifoglioPower: '@',
				statsQuadrifoglioAcceleration: '@',
				disclaimers: '@',
				jellyParameters: '@'
			}
		});

	function directorMiniBp(
		$scope,
		$rootScope,
		$window,
		brandBp,
		alfaConfigPipeline,
		configService,
		externalConfigLoader,
		fcaGeolocator,
		cookieLocation,
		trimService
	) {
		'ngInject';

		const $ctrl = this;
		let lastScroll = 0;
		$ctrl.downPayment = 5000;
		$ctrl.pricingList = null;
		$ctrl.drivetrainsObject = {};
		$ctrl.useAlfaromeoQuadrifoglioStats = false;
		$ctrl.minibpAnalyticsIdBase = 'build-price';
		$ctrl.stats = {};

		const paintEcc = '0082';
		const irisRootURl = externalConfigLoader
			.loadConfig('FCA_SITES_CONFIG')
			.getConfig('irisJellyBaseUrl') +
			"/mediaserver/iris?client=FCAUS&market=U&brand=Y";

		const SELECT_OPTION_STATUS = Object.freeze({
			complete: 'COMPLETE',
			musthave: 'MUSTHAVE',
		});

		$ctrl.language = window.FCA_SITES_CONFIG.language;
		$ctrl.queryParams = '';

		$ctrl.location = {
			province: 'ON'
		};

		$scope.$on('navigation: payment-type-changed', (_, data) => {
			$ctrl.paymentType = data.type;
		});

		$ctrl.$onInit = () => {
			$ctrl.location = cookieLocation.getLocation() || $ctrl.location;
			$ctrl.queryParams = addQueryParametersToUrl($ctrl.queryParams);
			$ctrl.hashParameters = getHashParameters();

			$ctrl.provinceCode = $ctrl.location.province;

			$ctrl.trimGroupObj = {};

			if ($ctrl.trimGroupJson) {
				$ctrl.trimGroupJson = $ctrl.trimGroupJson.replaceAll("'", "\"");
				$ctrl.trimGroupObj = angular.fromJson($ctrl.trimGroupJson);
			}

			$ctrl.modelYearIdList = getModelYearIdList();

			$ctrl.modelYearIdList.forEach((modelYearId) => {
				$ctrl.modelYearId = modelYearId;
				$ctrl.getModelYearPricing($ctrl.provinceCode);
			})

			$scope.$on(fcaGeolocator.getLocationChangedEvent(), (_, data) => {
				$ctrl.location = data[0];
				$ctrl.provinceCode = data[0].province;
				if (!$ctrl.loadInProgress) {
					getSwatches();
				}
			});

			window.addEventListener('scroll', _ => {
				if (!$ctrl.scrollPaused) {
					lastScroll = window.scrollY || window.pageYOffset;
				}
			});
		}

		$scope.$on('trim-service:trim-pricing-update', (event, data) => {
			if (data.pricingData === "") {
				return;
			}

			updatePricingList(data);
			updatePricingObject();

			getSwatches();
		});

		function updatePricingList(data) {
			$ctrl.pricingList = [];
			let trimArray = data.pricingData;

			trimArray.forEach(trimGroup => {
				trimGroup.data.forEach(trim => {
					if ($ctrl.trimGroupJson.includes(trim.acode) && trim.irisJellyRendering) {
						$ctrl.pricingList.push(trim);
					}
				});
			});
		}

		function updatePricingObject() {
			if ($ctrl.pricingList != null) {
				for (let pricingObject of $ctrl.pricingList) {
					if (pricingObject.acode) {
						let cashObject = pricingObject.cash;

						if (cashObject) {
							pricingObject.startingAtPrice = Math.ceil(cashObject.price);
						}
					}
				}
			}
		}

		function getSwatches() {
			$ctrl.loadInProgress = true;
			brandBp.getSwatches({brand: $ctrl.brand, nameplate: $ctrl.nameplate, year: $ctrl.year, modelYearId: $ctrl.modelYearId}, handleSwatchesResponse);
		}

		function handleSwatchesResponse(response) {
			if (!response) {
				return;
			}

			$ctrl.swatches = response;
			getTaxes();
		}

		function getTaxes() {
			brandBp.getTaxes({
				modelYearId: $ctrl.modelYearId
			}, handleTaxesResponse);
		}

		function handleTaxesResponse(response) {
			$ctrl.taxes = response.find(taxes => taxes.provinceCode === $ctrl.provinceCode);

			getTrims();
		}

		$ctrl.getModelYearPricing = (provinceCode) => {
			trimService.getTrimPricing(provinceCode, $ctrl.modelYearId, $ctrl.brand, $ctrl.nameplate, $ctrl.year);
		}

		function getTrims() {
			brandBp.getTrims({
				language: $ctrl.language,
				brand: $ctrl.brand,
				nameplate: $ctrl.nameplate,
				year: $ctrl.year,
				modelYear: $ctrl.modelYearId,
			}, handleTrimResponse);
		}

		function handleTrimResponse(response) {
			if (!response) {
				return;
			}

			$ctrl.trims = response;
			$ctrl.trims.trimGroups = $ctrl.trims.trimGroups.filter(trimGroup => trimGroup.jellyRenderingType === 'iris');
			$ctrl.setAvailableDriveTrainValues($ctrl.trims.drivetrains);
			$ctrl.getModelStats($ctrl.acode);
			$ctrl.refreshCurrentVehicleInfo();

			getConfig();
		}

		$ctrl.onModelSelected = (model) => {
			if (model && model.acode) {
				$ctrl.acode = model.acode;
				$ctrl.getModelStats($ctrl.acode);
				$ctrl.modelYearId = model.modelYearId;
				getTrims();
				$ctrl.refreshCurrentVehicleInfo();
				getConfig();
			}
		};

		$ctrl.setAvailableDriveTrainValues = (drivetrains) => {
			if (drivetrains) {
				drivetrains.forEach(drivetrain => {
					if (drivetrain.description.includes('Rear') || drivetrain.description.includes('propulsion arrière')) {
						drivetrain.customId = 'rwd';
						$ctrl.drivetrainsObject['rwd'] = drivetrain;

					} else if (drivetrain.description.includes('All') || drivetrain.description.includes('transmission intégrale')) {
						drivetrain.customId = 'awd';
						$ctrl.drivetrainsObject['awd'] = drivetrain;
					}
				});
			}
		};

		$ctrl.refreshCurrentVehicleInfo = () => {
			setCurrentTrimAndDriveTrain($ctrl.trims.trimGroups, $ctrl.trims.drivetrains);

			$ctrl.vehicle = $ctrl.year + "_" + $ctrl.currentTrim.mfgCode.substr(0, 2);

			if ($ctrl.brand === 'alfaromeo') {
				$ctrl.vehicleName = `${$ctrl.nameplate.toUpperCase()}${$ctrl.trimName.trim() !== '' ? ' ' + $ctrl.trimName.toUpperCase() : ''}`;
			} else if ($ctrl.withModelName) {
				$ctrl.vehicleName = `${$ctrl.trimName.trim() !== '' ? ' ' + $ctrl.trims.modelName.toUpperCase() + ' ' + $ctrl.trimName.toUpperCase() : ''}`;
			} else {
				$ctrl.vehicleName = `${$ctrl.trimName.trim() !== '' ? ' ' + $ctrl.trimName.toUpperCase() : ''}`;
			}

			$ctrl.modelsList = $ctrl.trims.trimGroups;

			$ctrl.manageStatsForQuadrifoglio();
		};

		$ctrl.getModelStats = (model) => {
			if ($ctrl.trimGroupObj[model] && $ctrl.trimGroupObj[model].statistics) {
				$ctrl.stats = $ctrl.trimGroupObj[model].statistics;
			}
		}

		function setCurrentTrimAndDriveTrain(trimGroups, drivetrains) {
			if (!trimGroups) {
				return;
			}

			for (let trimGroup of trimGroups) {
				for (let trim of trimGroup.trims) {
					let priceItemIndex = $ctrl.pricingList.findIndex(item => item.acode == trim.acode);

					if (trim.acode === $ctrl.acode) {
						$ctrl.trimName = trimGroup.code;
						$ctrl.currentTrim = trim;
						$ctrl.currentTrimGroup = trimGroup;
						$ctrl.currentBuildAndPriceURL = $ctrl.vehicleBaseUrl + '/build-and-price' + "/" + $ctrl.brand + "/" + $ctrl.nameplate + "/" +
							$ctrl.year + "/" + $ctrl.modelYearId + "/" + $ctrl.acode;

						$ctrl.currentSearchNewInventoryURL = $ctrl.vehicleSearchNewInventoryUrl +
							($ctrl.trimName.trim() != '' ? $ctrl.trimName.trim() : $ctrl.nameplate.trim());

						if (trimGroup.trimIrisJellyOptions.irisJellyParametersMiniBuildAndPrice) {
							$ctrl.jellyParameters = trimGroup.trimIrisJellyOptions.irisJellyParametersMiniBuildAndPrice;
						} else if (trimGroup.trimIrisJellyOptions.irisJellyParameters) {
							$ctrl.jellyParameters = trimGroup.trimIrisJellyOptions.irisJellyParameters;
						} else {
							$ctrl.jellyParameters = undefined;
						}

						if (priceItemIndex >= 0) {
							$ctrl.currentStartingPrice = getFormattedPriceValue($ctrl.pricingList[priceItemIndex].startingAtPrice);
						}
					}

					if (priceItemIndex >= 0) {
						$ctrl.pricingList[priceItemIndex].drivetrain = trim.drivetrain;
						$ctrl.pricingList[priceItemIndex].label = $ctrl.nameplate + ' ' + trimGroup.code;
					}

				}
			}

			if (drivetrains) {
				$ctrl.currentDrivetrain = drivetrains.find(drivetrain => drivetrain.id === $ctrl.currentTrim.drivetrain);
			}
		}

		function getFormattedPriceValue(priceString) {
			let priceValue = 0;

			if (priceString != "") {
				let value = parseFloat(priceString);
				if (!isNaN(value)) {
					priceValue = value;
				}
			}

			return priceValue;
		}

		$ctrl.manageStatsForQuadrifoglio = () => {
			$ctrl.useAlfaromeoQuadrifoglioStats = false;
			if ($ctrl.statsQuadrifoglioFound && $ctrl.brand === 'alfaromeo' && $ctrl.trimName.toLowerCase().indexOf('quadrifoglio') >= 0) {
				$ctrl.useAlfaromeoQuadrifoglioStats = true;
			}
		};

		function getConfig() {
			const savedConfig = brandBp.getSessionStoredConfiguration(
				$ctrl.brand,
				$ctrl.modelYearId,
				$ctrl.nameplate,
				$ctrl.currentTrimGroup,
				$ctrl.year
			);

			const scratchSave = savedConfig ? savedConfig.scratchSave :
				$ctrl.hashParameters.scratchsave ? LZString.decompressFromEncodedURIComponent($ctrl.hashParameters.scratchsave) : '';

			brandBp.getConfig({
				acode: $ctrl.acode,
				language: $ctrl.language,
				packageCode: savedConfig ? savedConfig.packageCode : $ctrl.currentTrim.packages[0].code,
				provinceCode: $ctrl.provinceCode,
				modelYearId: $ctrl.modelYearId,
				nameplateCode: $ctrl.nameplate,
				year: $ctrl.year,
				scratchSave: scratchSave,
				brand: $ctrl.brand
			}, handleConfigResponse);
		}

		function handleConfigResponse(response) {
			$ctrl.config = response.data;

			if ($ctrl.config.exteriorSideSection.categories != null && $ctrl.config.exteriorSideSection.categories.length == 0) {

				let wheelType = $ctrl.config.wheel
				wheelType.categories[0].view = "thumbnail-big"
				$ctrl.config.exteriorSideSection = wheelType
			}
			const mfgCode = $ctrl.currentTrim.mfgCode.substr(0, 2);
			$ctrl.mfgCode = mfgCode;
			const nameWithYear = `OC${$ctrl.year.substr(2,2)}U`
			const optionBaseUrl = irisRootURl +
				`&vehicle=${$ctrl.vehicle}` +
				`&name=${nameWithYear}___${mfgCode}_____`;

			const swatchData = {
				data: $ctrl.swatches,
				baseUrl: window.FCA_SITES_CONFIG.mediaBaseUrl
			};

			let onlyShowEccCodes = [
				alfaConfigPipeline.irisOptionsEccs.wheels,
				alfaConfigPipeline.irisOptionsEccs.brakes,
				alfaConfigPipeline.irisOptionsEccs.interiorColours,
				alfaConfigPipeline.irisOptionsEccs.exteriorPrimaryColours
			];
			const {
				allSelectedOptions,
				sections,
				exclusiveSections,
				jellyCategories
			} =
			alfaConfigPipeline.makeViewConfig($ctrl.config, optionBaseUrl, swatchData, onlyShowEccCodes);

			$ctrl.allSelectedOptions = allSelectedOptions;
			$ctrl.sections = exclusiveSections;
			$ctrl.jellyCategories = jellyCategories;

			$ctrl.pricing = $ctrl.config.pricing;

			configService.parseConfiguration($ctrl.config);

			$ctrl.loadInProgress = false;
			setJellyData();

			brandBp.saveConfigurationToSession(
				$ctrl.acode,
				$ctrl.brand,
				$ctrl.modelYearId,
				$ctrl.nameplate,
				$ctrl.currentTrim.packages[0].code,
				$ctrl.config.scratchSave,
				$ctrl.currentTrimGroup,
				$ctrl.year
			);
		}

		function setJellyData() {
			let trimIrisJellyOptions = $ctrl.currentTrimGroup.trimIrisJellyOptions;
			let cposVehicleType = trimIrisJellyOptions.cposVehicleType != null ? trimIrisJellyOptions.cposVehicleType : '';
			let packageCode = trimIrisJellyOptions.packageCodeOverride !== undefined ? trimIrisJellyOptions.packageCodeOverride : $ctrl.currentTrim.packages[0].code;

			if (trimIrisJellyOptions.irisAdditionalOptions !== undefined) {
				$ctrl.jellyCategories.options = $ctrl.jellyCategories.options + "," + trimIrisJellyOptions.irisAdditionalOptions;
			}

			$ctrl.jellyData = {
				jellyCategories: $ctrl.jellyCategories,
				vehicle: $ctrl.vehicle,
				mfgCode: $ctrl.currentTrim.mfgCode,
				packageCode: packageCode,
				cposVehicleType: cposVehicleType
			};
		}

		$ctrl.selectOption = option => {
			if (!option ||
				!$ctrl.currentTrim ||
				(option.selected && !option.isDeselectable)) {
				return;
			}
			$ctrl.lastSelectedOption = option;
			const data = {
				acode: $ctrl.acode,
				modelYearId: $ctrl.modelYearId,
				nameplateCode: $ctrl.nameplate,
				year: $ctrl.year,
				brandCode: $ctrl.brand,
				provinceCode: $ctrl.provinceCode,
				packageCode: $ctrl.currentTrim.packages[0].code,
				optionCode: option.code,
				scratchSave: $ctrl.alertWindowStatus === SELECT_OPTION_STATUS.musthave ? $ctrl.musthaveScratchSave : $ctrl.config.scratchSave,
				language: $ctrl.language
			};
			brandBp.selectOption(data, handleSelectOptionResponse, handleSelectOptionError);
		}

		function handleSelectOptionError(errorObject, $event) {
			console.error(errorObject);
			$rootScope.$broadcast('options-selector:show-missed-alert', {validOption: false});

			if ($event) {
				$event.preventDefault();
			}
		}

		function handleSelectOptionResponse(response) {
			const status = response.data.status;
			$ctrl.alertWindowStatus = status;

			if (status === SELECT_OPTION_STATUS.complete) {

				if(response.data.delta.selected.length === 0) {
					handleSelectOptionError();
					return;
				}

				handleCompleteResponse(response.data);
			} else if (status === SELECT_OPTION_STATUS.musthave) {
				handleMusthaveReponse(response.data);
			}
		}

		function handleCompleteResponse(response) {
			const deltaWarrantsAlert = (response.delta.selected.length + response.delta.unselected.length) > 2;

			if (!deltaWarrantsAlert) {
				$ctrl.showAlert = false;
				resumeScrolling();
				handleConfigResponse({
					data: response.configuration
				});
				return;
			}

			$ctrl.lastReceivedConfiguration = response.configuration;

			$ctrl.alertWindowConfirmed();
		}

		function handleMusthaveReponse(response) {
			$ctrl.musthaveScratchSave = response.scratchSave;
			$ctrl.alertWindowMainOption = response.options.shift();
			$ctrl.alertWindowMusthaveChoices = response.options;
			$ctrl.alertWindowStatus = response.status;
			$ctrl.alertWindowSelection(response.options[0]);
		}

		$ctrl.alertWindowConfirmed = () => {
			handleConfigResponse({
				data: $ctrl.lastReceivedConfiguration
			});
			$ctrl.alertWindowCancelled();
			$ctrl.lastReceivedConfiguration = null;
		}

		$ctrl.alertWindowCancelled = () => {
			$ctrl.musthaveScratchSave = $ctrl.alertWindowMainOption =
				$ctrl.alertWindowIncludedOptions = $ctrl.alertWindowRemovedOptions =
				$ctrl.alertWindowMusthaveChoices = $ctrl.alertWindowStatus = null;
			$ctrl.showAlert = false;
			resumeScrolling();
		}

		$ctrl.alertWindowSelection = (option) => {
			$ctrl.selectOption(option);
		}

		const resumeScrolling = () => {
			$ctrl.scrollPaused = false;
			$('body').removeClass('noScroll');

			if (lastScroll !== undefined) {
				window.scrollTo(0, lastScroll);
			}
		};

		$ctrl.getSelectedColorDescription = () => {
			if (!$ctrl.allSelectedOptions) return '';
			const selectedPaint = $ctrl.allSelectedOptions.find(option => option.ecc === paintEcc);
			return selectedPaint.description;
		}

		$ctrl.generateAltText = () => {
			let altText = '';
			let colourDescription = $ctrl.getSelectedColorDescription();
			altText = $ctrl.year + ' ' + $ctrl.brand + ' ' + $ctrl.nameplate;
			if(window.FCA_SITES_CONFIG.language == 'fr') {
				altText = $ctrl.brand + ' ' + $ctrl.nameplate + ' ' + $ctrl.year;
			}
			altText += ' ' + $ctrl.trimName + ' ' + $ctrl.currentDrivetrain.description + ' ' + colourDescription;
			return altText;
		};

		function getModelYearIdList() {
			if ($ctrl.trimGroupObj) {
				let result = [];

				Object.values($ctrl.trimGroupObj).forEach((trimGroup) => {
					if (trimGroup.trim && !result.includes(trimGroup.trim.modelYearId)) {
						result.push(trimGroup.trim.modelYearId);
					}
				});

				return result;
			}
		}
	}
})();
