(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaCustomizerBuildScenario22', fcaCustomizerBuildScenario22);

	function fcaCustomizerBuildScenario22() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				location: '<',
				sniSearchUrl: '@',
				scenario2DelayInMinutes: '@'
			},
			controllerAs: 'customizerBuildScenario22',
			controller: fcaCustomizerBuildScenario22Controller
		};

		function fcaCustomizerBuildScenario22Controller($scope, $rootScope, configService, $compile, $http, userAccountService, $window, scenarioOrchestrator, gtmAnalytics) {
			'ngInject';
			const $ctrl = this;

			$ctrl.savedSvg = document.querySelectorAll('.addToSaved');

			// other vars
			$ctrl.trimGroup = '';
			$ctrl.startingAtPrice = '';
			$ctrl.trimIndex = '';
			$ctrl.totalDiscount = '';
			$ctrl.buildAndPriceUrl = '';
			$ctrl.offersSiteLink = '';
			$ctrl.language = FCA_SITES_CONFIG.language;
			$ctrl.MQ_JELLY = $rootScope.FCA_MQ_JELLY;

			// disclaimers
			$ctrl.disclaimers = {
				netAmount: angular.element('[data-disclaimer-code="leg-net-amount"]').html(),
				msrp: angular.element('[data-disclaimer-code="leg-msrp-sni"]').html(),
				finance: angular.element('[data-disclaimer-code="leg-financing"]').html(),
				lease: angular.element('[data-disclaimer-code="leg-leasing"]').html(),
				dealerPricing: angular.element('[data-disclaimer-code="leg-dealer-pricing"]').html()
			};

			//analytics tagging values for the popup
			$ctrl.analyticsObject = [
				"perso-buildandprice-completedbp-mysaved-save-cta",
				"perso-buildandprice-completedbp-mysaved-exit"
			];

			$ctrl.$onInit = () => {
				scenarioOrchestrator.registerScenario('fcaPersonalizationScenario_2_2');

				$(document).ready(() => {
					const sliverbannerElement = document.querySelector('.HP_sliverBanner + .HP_backgroundBlock');

					if(sliverbannerElement) {
						sliverbannerElement.classList.add('js-top-position-reset');
					}

					//convert it to array because there is two instance of this node in
					//the module. one for desktop and one for mobile
					$ctrl.savedButtons = Array.from($ctrl.savedSvg);
					$ctrl.savedButtons.forEach(element => {
						element.addEventListener("click", () => {
							if (element.querySelector('path').style.fill !== 'black') {
								fillFavoriteHeart();
							} else {
								if (element.querySelector('path').style.fill === "black") {
									emptyFavoriteHeart();
								}
							}
						})
					});

				});
				$ctrl.setLocalStorageItemData();
			};

			$rootScope.$on('fca-modal-is-closed', () => {
				emptyFavoriteHeart();
			});

			$ctrl.setLocalStorageItemData = () => {


				let trigger = scenarioOrchestrator.canShowModal('fcaPersonalizationScenario_2_2');
				console.debug('scenario 2.2 triggered', trigger);

				if (trigger) {

					let data = $ctrl.getLocalStorageItem();
					$ctrl.acode = data.configuration.acode;
					$ctrl.nameplateCode = data.configuration.nameplateCode;
					$ctrl.modelYearId = data.configuration.modelYearId;
					$ctrl.brand = data.configuration.brand;
					$ctrl.brandCode = data.configuration.brand;
					$ctrl.modelYear = data.configuration.year;
					$ctrl.scratchSave = data.configuration.scratchSave;

					$ctrl.jellyUrl = data.legacyJellyUrl;
					if (!$ctrl.jellyUrl) {
						$ctrl.jellyUrl = data.irisJellyUrl;
					}

					$ctrl.setSniOffer(data.configuration.modelYearId, data.trimGroup, data.colorCode);
					$ctrl.setSniLink(data.configuration.brand, data.configuration.nameplateCode);
				} else {
					$ctrl.isActive = false;
				}

			};

			$ctrl.setSniOffer = (modelYearId, trimGroupCode, colorCode) => {
				// TODO add ajax call code to get sni offer, and show modale accordingly
				let latitude = $ctrl.location.latitude;
				let longitude = $ctrl.location.longitude;
				let url= `/data/inventories/jeep/search/personalization/vehicle?latitude=${latitude}&longitude=${longitude}&modelYearId=${modelYearId}&trimGroupCode=${trimGroupCode}&colorCode=${colorCode}`;
				$http.get(url).then(success, error);
				function success(response) {
					$ctrl.data = response.data;
					//double for because IE11 doesn't like double forEach
					for(let i = 0; i < $ctrl.data.jellies.length; i++) {
						for(let j = 0; j < $ctrl.data.jellies[i].jellies.length; j++) {
							if($ctrl.data.jellies[i].jellies[j].resolutionCode === "DESKTOP_RETINA") {
								$ctrl.jellyPath = $ctrl.data.jellies[i].jellies[j].path;
							}
						}
					}

					$ctrl.data.incentAmount = Math.floor(parseInt($ctrl.data.incentAmount, 10));
					$ctrl.data.dealerDiscountStart = {
						'en':'Dealer Price includes',
						'fr':'Le prix du concessionnaire comprend'
					};
					$ctrl.data.dealerDiscountEnd = {
						'en':` in ${$ctrl.data.brandName[$ctrl.language]} Discounts`,
						'fr':` en rabais ${$ctrl.data.brandName[$ctrl.language]}`
					};

					$ctrl.data.assembledName = {
						'en': $ctrl.data.nameplateName.en+" "+$ctrl.data.modelYear+" "+$ctrl.data.brandName.en+" "+$ctrl.data.trimGroup.en,
						'fr': $ctrl.data.nameplateName.fr+" "+$ctrl.data.modelYear+" "+$ctrl.data.brandName.fr+" "+$ctrl.data.trimGroup.fr,
					};

					if ($ctrl.data.jellies) {
						$ctrl.offerIrisJellies = $ctrl.data.jellies;
					}

					$ctrl.markFavoriteVehicles();
					$ctrl.strippedDealerLink = response.data.dealer.url.replace("https://","");
					$ctrl.isActive = true;
				}
				function error(error) {
					console.error('Response error', error);
					if (error.status == 404) {
						setNotFound(true);
					}
				}
			};

			function setNotFound(notFound) {
				let scenarioData = $ctrl.getLocalStorageItem();

				// will not search vehicle until end of expiryDate if not found.
				scenarioData.notFound = notFound;

				window.FcaCookieChecker.addLocalStorage($ctrl.getLocalStorageKey(), JSON.stringify(scenarioData));
			}

			$ctrl.setSniLink = (brandCode, nameplateCode) => {
				$ctrl.sniLink = $ctrl.sniSearchUrl + brandCode + "/" + nameplateCode;
			};

			$ctrl.formatPrice = price => {
				let separator = document.documentElement.lang === 'en' ? ',' : ' ';
				return price.replace(/\B(?=(\d{3})+(?!\d))/g, separator).slice(0, -3);
			};

			/**
			 * @todo to be adjusted for 2.2 Add to my saved feature
			 * @todo reuse user account login from 1.2 for the favorite feature
			 */

			$ctrl.addToFavorites = (vehicle, event) => {

				if (userAccountService.isLibraryAvailable()) {
					if (UA.isUserLoggedIn()) {
						if (vehicle.favorited === true) {
							//backend call to remove favorite
							UA.removeInventoryVehicle(vehicle.favoriteId).then(
								() => $scope.$apply(() => vehicle.favorited = false)
							);
						} else {
							userAccountService.addToFavorites(vehicle).then((response) => {
								//need $scope.$apply for update display; without, need to click outside the container to update the heart
								$scope.$apply(() => {
									vehicle.favorited = true;
									vehicle.favoriteId = response.id;
								});
							});
						}
					} else {
						$ctrl.favoritedVehicle = vehicle;
					}
					tagAnalyticsPopup();
				}
			};

			$scope.$on('ua:userSignInSuccess', (event, data) => {
				UA.retrieveAndCheckIsUserLoggedIn().finally(() => {
					userAccountService.addToFavorites($ctrl.favoritedVehicle).then((response) => {
						//need $scope.$apply for update display; without, need to click outside the container to update the heart
						$scope.$apply(() => {
							$ctrl.favoritedVehicle.favorited = true;
							$ctrl.favoritedVehicle.favoriteId = response.id;
						});
					});
					$ctrl.markFavoriteVehicles();
					fillFavoriteHeart();

				});
			});

			$ctrl.markFavoriteVehicles = () => {
				if (UA.isUserLoggedIn()) {
					UA.getInventoryVehicles().then((favoriteVehiclesList) => {
						let favoriteVehicle = favoriteVehiclesList.find((favorite) => favorite.vin === $ctrl.sniData.vin);
						if (favoriteVehicle !== undefined) {
							$ctrl.data.favorited = true;
							$ctrl.data.favoriteId = favoriteVehicle.id;
							fillFavoriteHeart();
						}
					});
				}
			};

			$ctrl.getLocalStorageItem = () => {
				let result = {};
				let storageItem = $window.localStorage.getItem($ctrl.getLocalStorageKey());
				if (storageItem) {
					result = JSON.parse(storageItem);
				}
				return result;
			};

			$ctrl.getLocalStorageKey = () => {
				return 'fcaPersonalizationScenario_2';
			};

			function fillFavoriteHeart() {
				$ctrl.savedButtons.forEach( element => {
					element.querySelector('path').style.fill="black";
					element.querySelector('path').style.stroke="black";
				});
			}

			function emptyFavoriteHeart() {
				$ctrl.savedButtons.forEach( element => {
					element.querySelector('path').style.stroke="black";
					element.querySelector('path').style.fill="rgba(0,0,0,0)";
				});
			}
			function tagAnalyticsPopup() {
				setTimeout(() => {
					let emailField = document.querySelector(".C_USI-form-row input[type=email]");
					let postalCode = document.querySelector(".C_USI-form-row input[name=postalCode]");
					let saveButton = document.querySelector("#fca-ua-standalone-login-button>button[type=submit]");
					let fbButton = document.querySelector("#fca-ua-facebook-login-button>button[type=button]");
					let fbButtonSpan = document.querySelector("#fca-ua-facebook-login-button>button[type=button]>span");
					let gMailButton = document.querySelector("#fca-ua-google-login-button>button[type=button]");
					let closeCta = document.querySelector(".C_USI-popup-close>i");
					let closeCtaButton = document.querySelector(".C_USI-popup-close");

					closeCtaButton.setAttribute("data-analyticsid","perso-buildandprice-completedbp-mysaved-exit");
					closeCtaButton.setAttribute("data-analyticsvalue","perso-buildandprice-completedbp-mysaved-exit");
					closeCta.setAttribute("data-analyticsid","perso-buildandprice-completedbp-mysaved-exit");
					closeCta.setAttribute("data-analyticsvalue","perso-buildandprice-completedbp-mysaved-exit");

					let elementArray = [saveButton, closeCta];

					elementArray.forEach( function (element, index) {
						element.setAttribute('data-analyticsid', $ctrl.analyticsObject[index]);
						element.setAttribute('data-analyticsvalue', $ctrl.analyticsObject[index]);
					});

					saveButton.addEventListener("click", () => {
						if(postalCode.value !== "" && emailField.value !== "") {
							addTracking("", 'perso-buildandprice-completedbp-mysaved-postal');
							addTracking("", 'perso-buildandprice-completedbp-mysaved-email');
							addTracking("", 'perso-buildandprice-completedbp-mysaved-submit-');
						}
					});

					gMailButton.addEventListener('click', ()=> {
						addTracking("", 'perso-buildandprice-completedbp-mysaved-savewithgmail');
					});

					fbButtonSpan.addEventListener('click', ()=>{
						addTracking('','perso-buildandprice-completedbp-mysaved-savewithfacebook');
					});

					$scope.$apply();

				},300);
			}

			function addMinutes(dt, minutes) {
				return new Date(dt.getTime() + minutes*60000);
			}


			/**
			 *
			 * @param elementToTrack is the element which we want to track
			 * @param trackingID is the analyticsID
			 */
			function addTracking(elementToTrack, trackingID) {
				gtmAnalytics.trackEvent('event', {
					category:  `${window.FCA_SITES_CONFIG.pageCode} personalization`,
					label: `${trackingID}`
				});
			}
		}
	}
})();
