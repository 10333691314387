(function(ng) {
	'use strict';

	angular
		.module('alfaromeo.alfistiVideo')
		.controller('alfaromeoAlfistiVideoController', DialogController);

	function DialogController($scope, $rootScope, ngDialog, $translate, $sce, $element, $timeout) {
		'ngInject';

		let dialogData = $scope.ngDialogData;

		this.dialogTitle = $translate.instant(dialogData.dialogTitle);
		$scope.youtubeUrl = $sce.trustAsResourceUrl(dialogData.youtubeUrl);
		if(window.FcaYoutubeVideoNoCookie) {
			$scope.youtubeUrl = $sce.trustAsResourceUrl(window.FcaYoutubeVideoNoCookie.updateYoutubeSrc(dialogData.youtubeUrl));
		}

		$timeout(() => {
			this.setFocusTrap();
		});

		this.close = ($evt) => {
			if ($evt) {
				$evt.preventDefault();
				$evt.stopPropagation();
			}
			$element[0].removeEventListener('keydown', this.focusTrap);
			ngDialog.close();
		};

		this.focusTrap = (e) => {
			const focusableElements = $element.find('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
			const firstFocusableElement = focusableElements[0];
			const lastFocusableElement = focusableElements[focusableElements.length - 1];

			if (e.key === 'Tab' || e.code === 'Tab') {
				if ( e.shiftKey ) {
					if (document.activeElement === firstFocusableElement) {
						lastFocusableElement.focus();
						e.preventDefault();
					}
				} else {
					if (document.activeElement === lastFocusableElement) {
						firstFocusableElement.focus();
						e.preventDefault();
					}
				}
			} else if(e.key === 'Escape' || e.code === 'Escape') {
				this.close(e);
			} else {
				return;
			}
		};

		this.setFocusTrap = () => {
			const focusableElements = $element.find('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
			const firstFocusableElement = focusableElements[0];

			firstFocusableElement.focus();

			$element[0].addEventListener('keydown', this.focusTrap);
		};
	}
})(angular);
