/* eslint-disable indent */
(function() {
    angular
        .module('fca.calculator')
        .component('fcaApplyForFinancingCalculatorController', {
            controller: FcaApplyForFinancingCalculatorController,
            controllerAs: '$aFFCalcCtrl',
            templateUrl: '/calculator/apply-for-financing-calculator.html',
            bindings: {
                showLeaseTab: '<',
                showCtaFinancing: '<',
                showCtaContactDealer: '<',
                showSalesTax: '<',
                showSeeOptions: '<',
                showPaymentFrequency: '<',
                showPaymentSummary: '<',
                disclaimerBasePrice: '@',
                disclaimerFinancing: '@',
                disclaimerLeasing: '@',
                dealerCode: '@',
                location: '<',
                calculatorDefaultFinanceRate: '<',
                calculatorSliderStepSize: '<',
                acode: '@',
                modelYearId: '@',
                packageAndOptionsCode: '<',
                otherFees: '@',
                basePriceAndOptions: '@',
                applyForFinancingIncentivesTotal: '@',
                financeDiscountTotalBeforeTax: '<',
                financeDiscountTotalAfterTax: '<',
                downpayment: '<',
                tradeValue: '<',
                oweOnTrade: '<',
                financeFrequency: '@',
                financeTerm: '<',
                freight: '<',
                acTax: '<',
                greenLevy: '<',
                incentives: '<',
                pricing: '<',
                scratchSave: '<',
                vin: '@',
                disclaimers: '<',
                affiliateLogoSource: '@'
            }
        });

    function FcaApplyForFinancingCalculatorController($scope, $rootScope) {
        let $aFFCalcCtrl = this;

        $aFFCalcCtrl.isComponentLoaded = false;

        $aFFCalcCtrl.$onInit = () => {
            $aFFCalcCtrl.basePrice = Number($aFFCalcCtrl.basePriceAndOptions) + Number($aFFCalcCtrl.otherFees);
            $aFFCalcCtrl.downpayment = Number($aFFCalcCtrl.downpayment);
            $aFFCalcCtrl.tradeValue = Number($aFFCalcCtrl.tradeValue);
            $aFFCalcCtrl.oweOnTrade = Number($aFFCalcCtrl.oweOnTrade);
            $aFFCalcCtrl.applyForFinancingIncentivesTotal = Number($aFFCalcCtrl.applyForFinancingIncentivesTotal);
            $aFFCalcCtrl.financeDiscountTotalBeforeTax = Number($aFFCalcCtrl.financeDiscountTotalBeforeTax);
            $aFFCalcCtrl.financeDiscountTotalAfterTax = Number($aFFCalcCtrl.financeDiscountTotalAfterTax);
            $aFFCalcCtrl.freight = 0;
            $aFFCalcCtrl.acTax = 0;
            $aFFCalcCtrl.greenLevy = 0;
            $aFFCalcCtrl.discountBeforeTax = 0;
            $aFFCalcCtrl.discountAfterTax = 0;
            $aFFCalcCtrl.discountBeforeTaxTotal = 0;
            $aFFCalcCtrl.discountAfterTaxTotal = 0;
            $aFFCalcCtrl.discountTotal = 0;
            $aFFCalcCtrl.maxPrice = 0;
            $aFFCalcCtrl.paymentFinance = {};

            if ($aFFCalcCtrl.affiliateLogoSource) {
                window.FcaCookieChecker.addSessionStorage('affiliateLogoSource', $aFFCalcCtrl.affiliateLogoSource);
            } else {
                $aFFCalcCtrl.affiliateLogoSource = sessionStorage.getItem('affiliateLogoSource')
            }

            // Vehicle attributes
            $aFFCalcCtrl.options = 0;
            $rootScope.$on('apply-for-financing-calculator:reset-calculator', (event,data) => {
                if(Number($aFFCalcCtrl.basePriceAndOptions) > 0) {
                    $aFFCalcCtrl.setCalculatorValues();
                }
            });

            $scope.$$postDigest(function() {
                $aFFCalcCtrl.isComponentLoaded = true;
            });
        };

        $aFFCalcCtrl.setCalculatorValues = () => {
            $scope.$$postDigest(function() {
                $aFFCalcCtrl.downpayment = Number($aFFCalcCtrl.downpayment||0);
                $aFFCalcCtrl.tradeValue = Number($aFFCalcCtrl.tradeValue||0);
                $aFFCalcCtrl.oweOnTrade = Number($aFFCalcCtrl.oweOnTrade||0);
                $rootScope.$broadcast('calculator:set-aff-programs', {
                    downpayment: $aFFCalcCtrl.downpayment,
                    oweOnTrade: $aFFCalcCtrl.oweOnTrade,
                    pricing: $aFFCalcCtrl.pricing
                });
            });
        };
    }
})(angular);