(function () {
    angular
        .module('fca.userSignInForm')
        .component('userSignInForm', {
            controller: FcaUserSignInFormController,
            controllerAs: '$ctrlSignInForm',
            templateUrl: '/user-sign-in/sign-in-form/sign-in-form.html',
            bindings: {
                onSignIn: "<?",
                unsubscribe: "<?",
                analyticsCategory: '@',
                analyticsId: '@',
                analyticsValue: '@'
            }
        });

    function FcaUserSignInFormController($timeout, $scope, $rootScope, userAccountService, fcaUserIdPersistentService) {
        'ngInject';

        let $ctrlSignInForm = this;
        $ctrlSignInForm.siteCode = FCA_SITES_CONFIG.pageCode;

        $ctrlSignInForm.postalCodeRegex = /^(?!.*[dfioquDFIOQU])[a-vxyA-VXY][0-9][a-zA-Z] ?[0-9][a-zA-Z][0-9]$/;

        $ctrlSignInForm.$onInit = () => {

            $ctrlSignInForm.backendError = false;

            if (!$ctrlSignInForm.onSignIn) {
                $ctrlSignInForm.onSignIn = $ctrlSignInForm.defaultOnSignInSuccess;
            }

            $rootScope.$on('user-account: login-fail', (event, data) => {

              $ctrlSignInForm.backendError = true;
              $ctrlSignInForm.wrongPostalCode = false;
              $ctrlSignInForm.emailAlreadyExists = false;
              $ctrlSignInForm.facebookAlreadyExists = false;
              $ctrlSignInForm.googleAlreadyExists = false;

              $scope.$apply(() => {
                switch (data.message) {
                  case 'wrong-postal-code' :
                    $ctrlSignInForm.wrongPostalCode = true;
                    break;
                  case 'email-already-exists' :
                    $ctrlSignInForm.emailAlreadyExists = true;
                    break;
                  case 'facebook-already-exists' :
                    $ctrlSignInForm.facebookAlreadyExists = true;
                    break;
                  case 'google-already-exists' :
                    $ctrlSignInForm.googleAlreadyExists = true;
                    break;
                  default :
                    console.log('Failed on signIn');
                    break;
                }
              });
            });
        }

        $ctrlSignInForm.submitSigninForm = () => {
            if (!userAccountService.isLibraryAvailable()) {
                return;
            }
            let emailField = document.querySelector('input[name="email"]');
            let postalCodeField = document.querySelector('input[name="postalCode"]');
            $ctrlSignInForm.emailRequired = false;
            $ctrlSignInForm.postalCodeRequired = false;

            let email = $ctrlSignInForm.email;
            let postalCode = $ctrlSignInForm.postalCode;

            if ( typeof email === 'undefined' ) {
                $ctrlSignInForm.emailRequired = true;
                emailField.focus();
            }

            if ( typeof postalCode === 'undefined') {
                $ctrlSignInForm.postalCodeRequired = true;
                if (!$ctrlSignInForm.emailRequired) {
                    postalCodeField.focus();
                }
            }

            if ( typeof email === 'undefined' || typeof postalCode === 'undefined' ) {
                return;
            }

            UA.submitPopupForm(email, postalCode).then((response) => {
                if (response.status === 'FAIL') {
                  $rootScope.$broadcast('user-account: login-fail', {
                    message: response.message
                  });
                } else {
                    //set userId cookie and saving it to storage until the user logout
                    // redirct only on successCallBack
                    fcaUserIdPersistentService.getUserId(email, this.saveUserIdCookie, (error) => console.error(error));
                }
            });
        };

        $ctrlSignInForm.defaultOnSignInSuccess = () => {
            let language = window.FCA_SITES_CONFIG.language;

            window.location.replace(window.FCA_SITES_CONFIG.urls.myGarage[language]);
        };

        $ctrlSignInForm.saveUserIdCookie = () => {
            window.FcaCookieChecker.addSessionStorage("persistentID" ,fcaUserIdPersistentService.getCookie('userId'));
            $ctrlSignInForm.onSignIn();
        };
    }
})();

