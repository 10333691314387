(function (ng) {
    angular
        .module('fca.commonDirectives')
        .component('viewOfferInventory', {
            controller: FcaViewOfferInventory,
            controllerAs: '$ctrl',
            templateUrl: '/view-offer-inventory/view-offer-inventory.html',
            bindings: {
                userLocation: "<",
                dealerDisclaimer: "@",
                financeDisclaimer: "@",
                msrpDisclaimer: "@",
                netDisclaimer: "@",
                leaseDisclaimer: "@",
                disclaimerLegAmountSni: "@",
                maxIrisWidth: "@",
                offerId: "@",
                acode: "@",
                trimOpts: "@",
                trimpPkg: "@",
                sniBuyUrl: "@",
                eshopUrl: "@",
                eshopActivated: "@",
                eshopWindow: "@",
                eshopLabel: "@",
                sniDetailUrl: "@",
                offerYear: "@",
                showAllSniInventory: "<",
                madeInOntarioLogoPath: "@",
                madeInOntarioLogoAlt: "@",
                analyticsCategory: "@",
                analyticsId: "@",
                inventoryPaymentMode: "@",
                nameplateCode: "@"
            }
        });

    function FcaViewOfferInventory($http, fcaSniTrimService, $element, $rootScope, $timeout, $scope, fcaUserIdPersistentService, dealerDigitalRetailingService) {
        'ngInject';

        const $ctrl = this;

        $ctrl.paymentMode = $ctrl.inventoryPaymentMode;
        $ctrl.language = window.FCA_SITES_CONFIG.language;
        $ctrl.vehicles = {};
        $ctrl.detailTarget="_top";
        $ctrl.inventoryIcon = {
            'IN_STOCK': 'map',
            'IN_TRANSIT': 'truck',
            'ON_ORDER': 'paper'
        };
        $ctrl.inventoryStatus = {
            'IN_STOCK': 'sni.list.nameplate.on-dealer-lot',
            'IN_TRANSIT': 'sni.list.nameplate.in-transit',
            'ON_ORDER': 'sni.list.nameplate.on-order'
        };

        $ctrl.contactADealerUrl = ($ctrl.language == 'fr') ? "contacter-un-concessionnaire" : "contact-a-dealer";

        $ctrl.daaPaymentMode = '';

        $ctrl.$onInit = () => {
            // /* Slick needs no get Reinitialized on window Resize after it was destroyed */
            $(window).on('resize orientationchange', function () {
                $ctrl.initSlickCarousel();
            });

            $ctrl.paymentMode = $ctrl.inventoryPaymentMode;
            $ctrl.disclaimers = {
                dealer: $ctrl.dealerDisclaimer,
                msrp: $ctrl.msrpDisclaimer,
                regular: $ctrl.netDisclaimer,
                finance: $ctrl.financeDisclaimer,
                lease: $ctrl.leaseDisclaimer,
                legAmountSni: $ctrl.disclaimerLegAmountSni
            };

            $ctrl.checkAffiliateMode();
            $ctrl.checkCampaign();
            $ctrl.fetchAPIData();

            $rootScope.$on('nameplateOffersAccordion.accordionOpen', () => {
                $ctrl.fetchAPIData();
            });
            $rootScope.$on('nameplateOffersAccordion.accordionClose', () => {
                $ctrl.unSlick();
            });

            $scope.$on('daa-offer:set-payment-option', (_, data) => {
                $ctrl.daaPaymentMode = data;
            });

			$scope.$on('fca-open-collapse::is-expanded', () => {
				$timeout(() => {
                    $element.find('.viewOfferInventory-holder').slick('refresh');
                });
			});
        };

        $ctrl.initSlickCarousel = () => {
            /* Initializes a slick carousel only on mobile screens */
            if ($(window).width() > 768) {
                if ($element.find('.viewOfferInventory-holder').hasClass('slick-initialized')) {
                    $timeout(() => {
                        $element.find('.viewOfferInventory-holder').slick('unslick');
                    }, 400);
                }
                if ($ctrl.vehicles.length < 3) {
                    $($element.find('.viewOfferInventory-holder')[0]).addClass('unfull-list');
                }
            } else {
                if (!$element.find('.viewOfferInventory-holder').hasClass('slick-initialized')) {
                    $element.find('.viewOfferInventory-holder').slick({
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: false,
                        mobileFirst: true,
                        dots: true,
                        arrows: true
                    });
                }
                $timeout(() => {
                    $element.find('.viewOfferInventory-holder').slick('refresh');
                });
            }
        };

        $ctrl.unSlick = () => {
            if ($(window).width() <= 767) {
                $element.find('.viewOfferInventory-holder').slick('unslick');
            }
            $ctrl.vehicles = {};
        };

        $ctrl.changeTab = (newTab) => {
            $ctrl.paymentMode = newTab;
        };

        $ctrl.checkAffiliateMode = () => {
            storeAffiliateId();
            $ctrl.affiliateMode = isAffiliateMode();
            $ctrl.affiliateId = $ctrl.affiliateMode ? getStoredAffiliateId() : null;

            if($ctrl.affiliateMode && $ctrl.affiliateId) {
                $ctrl.affiliateUrl = getAffiliateIdUrlParam('');
            }

        };

        $ctrl.checkCampaign = () => {
            $ctrl.campaignCode = sessionStorage.getItem('campaignCode');
            if ($ctrl.campaignCode !== null && $ctrl.campaignCode !== 'null'
                && $ctrl.campaignCode !== '') {
                window.FcaCookieChecker.addSessionStorage('campaignCode', $ctrl.campaignCode);
            } else {
                sessionStorage.removeItem('campaignCode');
            }
        };

        $ctrl.fetchAPIData = () => {

            if (!$ctrl.nameplateCode) {
                $ctrl.nameplateCode = window.FCA_SITES_CONFIG.nameplateCode;
            }

            let ajaxUrl = (
                `/data/inventories/${window.FCA_SITES_CONFIG.name}/` +
                `otherVehicles/${$ctrl.nameplateCode}`
            );

            ajaxUrl += '?nbItems=3';

            if ($ctrl.userLocation && $ctrl.userLocation.longitude) {
                ajaxUrl += `&longitude=${$ctrl.userLocation.longitude}`;
                ajaxUrl += `&latitude=${$ctrl.userLocation.latitude}`;
            }

            if ($ctrl.affiliateId) {
                ajaxUrl += getAffiliateIdUrlParam();
            }

            if ($ctrl.campaignCode) {
                ajaxUrl += `&campaignCode=${$ctrl.campaignCode}`;
            }

            if($ctrl.showAllSniInventory) {
                ajaxUrl += `&year=${$ctrl.offerYear}`

                if ($ctrl.acode) {
                    ajaxUrl += `&acode=${$ctrl.acode}`;
                }
            } else {
                if ($ctrl.acode) {
                    ajaxUrl += `&acode=${$ctrl.acode}`;
                }

                if ($ctrl.trimOpts) {
                    ajaxUrl += `&trimOpts=${$ctrl.trimOpts}`;
                }

                if ($ctrl.trimpPkg) {
                    ajaxUrl += `&trimPkg=${$ctrl.trimpPkg}`;
                }
            }

            $http.get(ajaxUrl).then(response => {
                $ctrl.vehicles = response.data;
                $ctrl.initSlickCarousel();
            }, error => {
            })
        }


        $ctrl.getVDPUrl = (vehicle) => {
            let vdpUrl = $ctrl.sniDetailUrl + vehicle.vin;
            let eshopUrl = $ctrl.eshopUrl + "/t1?vin="+vehicle.vin+"&dealercode="+vehicle.dealerCode+"&pid="+$ctrl.getUserId()+"&vehicle_type=new";
            if (($ctrl.affiliateUrl !== '') && ($ctrl.affiliateUrl != null) && ($ctrl.affiliateUrl != "undefined")) {
                vdpUrl += "?"+$ctrl.affiliateUrl;
                eshopUrl += "&"+$ctrl.affiliateUrl;
            }
            eshopUrl += "#vehicleInformation";

            if ($ctrl.eshopActivated === 'true') {
                vdpUrl = eshopUrl;
                if ($ctrl.eshopWindow === "true") {
                    $ctrl.detailTarget = "_blank";
                }
            }
            return vdpUrl;
        };


        $ctrl.generateAltTag = (pModelYear, pBrandName, pNamepateName, pTrimGroup) => {
            let brandName = window.FCA_SITES_CONFIG.name;
            let altText = '';

            altText = pModelYear + ' ' + pBrandName + ' ' + pNamepateName + ' ' + pTrimGroup;
            if(window.FCA_SITES_CONFIG.language == 'fr') {
                altText = pBrandName + ' ' + pNamepateName + ' ' + pModelYear + ' ' + pTrimGroup;
            }
            return altText;
        }

        $ctrl.getUserId = () => {
            return fcaUserIdPersistentService.getCookie('userId');
        };

        $ctrl.getProcessCssClass = (vehicle) => {
            return dealerDigitalRetailingService.getCssClass(vehicle);
        }

        $ctrl.onStartBuyingProcessClick = (event, sniBuyUrl) => {
            return dealerDigitalRetailingService.onStartBuyingProcessClick(event, sniBuyUrl);
        }

        $ctrl.getBuyOnlineUrl = (vehicle, hashParams) => {
            if (vehicle.dealerDigitalRetailing && vehicle.dealerDigitalRetailing.enabled) {
                let digitalRetailingUrl = $ctrl.language == 'en' ? vehicle.dealerDigitalRetailing.urlEn : vehicle.dealerDigitalRetailing.urlFr;
                return dealerDigitalRetailingService.getDealerDigitalRetailingUrl(digitalRetailingUrl, vehicle.dealer.url, vehicle.vin);
            }

            let buyUrl = $ctrl.sniBuyUrl + vehicle.vin;

            if ($ctrl.utmTag) {
                buyUrl += $ctrl.utmTag;
            }

            if ($ctrl.affiliateParams) {
                buyUrl += (buyUrl.indexOf('?') == -1 ? '?' : '&') + $ctrl.affiliateParams;
            }

            if ($ctrl.campaignParams) {
                buyUrl += (buyUrl.indexOf('?') == -1 ? '?' : '&') + $ctrl.campaignParams;
            }

            if (hashParams) {
                buyUrl += (buyUrl.indexOf('?') == -1 ? '?' : '&') + hashParams;
            }

            return buyUrl;
        };

        $ctrl.sendToDealer = (brandCode, nameplateCode, trim, year, modelYearId, dealer) => {
            const dealerString= `#brand=${brandCode}&nameplate=${nameplateCode}&trim=${trim}&year=${year}&modelYearId=${modelYearId}&dealer=${dealer}`
            return $ctrl.contactADealerUrl + dealerString;
        }
    }
})(angular);
