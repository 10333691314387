(function () {
	angular
		.module('fca.salesAppointment')
		.service('fcaSalesAppointmentService', FcaSalesAppointmentService);

	function FcaSalesAppointmentService($http) {
		'ngInject';

		let $service = this;

		$service.baseUrl = '/data/dealers';

		$service.language = FCA_SITES_CONFIG.language;

		$service.appointmentListeners = [];

		/**
		 * Get the current appointment object from session storage
		 * @returns AppointmentObject
		 */
		$service.getFromStorage = () => {
			let result = null;
			let obj = sessionStorage.getItem($service.getKey());
			if (obj) {
				result = JSON.parse(obj);
			} else {
				result = {};
			}
			return result;
		};

		/**
		 * Set the current object in session storage
		 *
		 * @returns appointmentObject
		 */
		$service.setInStorage = (appointmentObject) => {
			let key = $service.getKey();
			if (!!appointmentObject) {
				window.FcaCookieChecker.addSessionStorage(key, JSON.stringify(appointmentObject));
			} else {
				sessionStorage.removeItem(key);
			}
			$service.notifyAppointmentListeners(appointmentObject);
		};

		/**
		 * Get the session storage key
		 *
		 * @returns {string}
		 */
		$service.getKey = () => {
			return "appointment-object";
		};

		/**
		 * Register a listener
		 *
		 * @param listener
		 */
		$service.registerAppointmentListener = (listener) => {
			$service.appointmentListeners.push(listener);
		};

		/**
		 * Notify all appointment listeners that appointment that an appointment has been choosen
		 */
		$service.notifyAppointmentListeners = () => {
			if ($service.appointmentListeners && $service.appointmentListeners.length > 0) {
				$service.appointmentListeners.forEach(function(listener) {
					if (listener) {
							listener();
					}
				});
			}
		};


		$service.appointmentChanged = () => {
			$service.notifyAppointmentListeners();
		}

		$service.localizeDate = (date) => {
			var localizedDateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
			var localizedDate = date.replace(/-/g, '\/').replace(/T.+/, '');
			localizedDate = new Date(localizedDate);
			localizedDate = localizedDate.toLocaleDateString($service.language + '-CA', localizedDateOptions);

			if ($service.language === 'fr') {
				localizedDate = localizedDate.charAt(0).toUpperCase() + localizedDate.slice(1);
			}

			return localizedDate;
		};

		$service.localizeHour = (hour) => {
			if ($service.language === 'fr') {
				const [time, modifier] = hour.split(' ');
				var [hours, minutes] = time.split(':');

				if (hours === '12') {
					hours = '00';
				}

				if (modifier === 'PM') {
					hours = parseInt(hours, 10) + 12;
				}

				return `${hours}:${minutes}`;
			} else {
				return hour;
			}
		}

		/**
		 * Get schedule for the dealer
		 *
		 * @param successCallback
		 * @param errorCallback
		 */
		$service.getDealerSchedule = (brandCode, dealerCode, successCallback, errorCallback) => {
			let url = $service.baseUrl + `/appointments/available/${brandCode}/${dealerCode}`;
			$http.get(url)
				.then((response) => {
					if (response.status === 200
							&& response.data) {
						successCallback(response.data);
					} else {
						errorCallback("error retrieving dealer schedule data: " + (response.status || '') + " response ");
					}
				})
				.catch((data, status, headers, config) => {
					errorCallback('error retrieving dealer schedule');
				});
		};

	}
})(angular);
