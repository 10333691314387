(function () {
	angular
	.module('fca.userAccount.myGarage')
	.service('pricingService', PricingService);

	function PricingService($rootScope, $http, $window, calculatorService) {

		const LAST_CALCULATED_DATE_STORAGE_KEY = 'fca-ua-builtVehiclesPricing-lastCalculatedDate';
		const PRICING_STORAGE_KEY = 'fca-ua-builtVehiclesPricing';

		let $service = this;

		$service.vehicles = [];

		$service.vehiclePricings = [];

		$service.pricingInvalidationDateString = "";

		$service.pricingValidityInHours = '12';

		$service.defaultFinanceRate = '6.99';

		$service.maxAcceptableLeaseRate = 5.00;

		$service.initPricingService = (pricingInvalidationDate, pricingValidityInHours, vehicles,
			calculatorDefaultFinanceRate, maxAcceptableLeaseRate) => {

			let initializedVehicleIds = [];

			$service.pricingInvalidationDateString = pricingInvalidationDate;
			if (pricingValidityInHours && pricingValidityInHours !== '') {
				$service.pricingValidityInHours = pricingValidityInHours;
			}
			$service.vehicles = vehicles;
			if (calculatorDefaultFinanceRate && calculatorDefaultFinanceRate !== '') {
				$service.defaultFinanceRate = calculatorDefaultFinanceRate;
			}
			if (maxAcceptableLeaseRate && maxAcceptableLeaseRate !== '') {
				$service.maxAcceptableLeaseRate = parseFloat(maxAcceptableLeaseRate);
			}

			// define what to do on ua:vehiclePricingCalculated event
			$rootScope.$on('ua:vehiclePricingCalculated', (event, data) => {
				// put pricing to map
				$service.vehiclePricings.set(data.vehicleId, data.vehiclePricing);
				// store map in local storage
				$window.FcaCookieChecker.addLocalStorage(PRICING_STORAGE_KEY, JSON.stringify(Object.fromEntries($service.vehiclePricings)), window.FcaCookieChecker.cookieGroups.FUNCTIONNAL_COOKIES);
				// store timestamp in local storage
				$window.FcaCookieChecker.addLocalStorage(LAST_CALCULATED_DATE_STORAGE_KEY, new Date().toISOString(), window.FcaCookieChecker.cookieGroups.FUNCTIONNAL_COOKIES);

				$rootScope.$broadcast('ua:vehiclePricingRetrieved', data);
			});

			if (isPricingHasExpired()) {
				// we clear pricing in local storage
				$window.FcaCookieChecker.addLocalStorage(PRICING_STORAGE_KEY, JSON.stringify(new Map()), window.FcaCookieChecker.cookieGroups.FUNCTIONNAL_COOKIES);
			}
			//retrieve pricings from local storage
			$service.vehiclePricings = new Map(Object.entries(JSON.parse($window.localStorage.getItem(PRICING_STORAGE_KEY))||{}));

			// load pricing for each vehicle if needed
			$service.vehicles.forEach((vehicle) => {
				//https://issues.nurun.com/browse/QFGBFI-573
				// TODO RM: we revert things done for https://issues.nurun.com/browse/QFGBFI-573 to go to production
				initializedVehicleIds.push(vehicle.id);

				if (!$service.vehiclePricings.has(vehicle.id)) {
					let pricingLoaded = loadPricing(vehicle);

					if(pricingLoaded) {
						initializedVehicleIds.push(vehicle.id);
					}
				} else {
					$rootScope.$broadcast('ua:vehiclePricingRetrieved', {
						vehicleId: vehicle.id,
						vehiclePricing: $service.vehiclePricings.get(vehicle.id)
					});
					initializedVehicleIds.push(vehicle.id);
				}
			});
			return initializedVehicleIds;
		};

		/**
		 * Return true if the pricing for built vehicles has expired
		 * @returns {boolean}
		 */
		let isPricingHasExpired = () => {
			let expired = false;
			let now = new Date();

			if (now > getPricingExpirationDate() || isPricingInvalidated()) {

				expired = true;
			}

			return expired;
		};

		let getPricingExpirationDate = () => {
			let lastCalculatedDate = getLastCalculatedDate();
			return lastCalculatedDate.setHours(
				lastCalculatedDate.getHours() + parseInt($service.pricingValidityInHours));
		};

		let isPricingInvalidated = () => {
			return $service.pricingInvalidationDateString !== ''
				&& getLastCalculatedDate() < new Date($service.pricingInvalidationDateString);
		};

		let getLastCalculatedDate = () => {
			// by default, pricings has been calculated few years ago (1970-01-01T00:00:00.000Z)
			let lastCalculatedDate = new Date(null);
			let lastCalculatedDateString = $window.localStorage.getItem(LAST_CALCULATED_DATE_STORAGE_KEY);
			if (lastCalculatedDateString !== null) {
				lastCalculatedDate = new Date(lastCalculatedDateString);
			}
			return lastCalculatedDate;
		};

		let loadPricing = (vehicle) => {

			//load configuration to get the net amount
			const params = {
				acode: vehicle.acode,
				language: vehicle.language,
				packageCode: vehicle.packageCode,
				provinceCode: vehicle.provinceCode,
				modelYearId: vehicle.modelYearId,
				nameplateCode: vehicle.nameplateCode,
				year: vehicle.year,
				brand: vehicle.brandCode,
				scratchSave: vehicle.scratchsave
			};

			// load config to get pricing
			$http.post('/api/buildandprice/config', params).then(r => {

				let netAmount = 0;
				if (r.data && r.data.pricing && r.data.pricing.monroney && r.data.pricing.cash) {

					let sumIncentives = 0;
					if(r.data.pricing.cash.incentives) {
						r.data.pricing.cash.incentives.forEach(incentive => {
							sumIncentives += parseInt(incentive.cash);
						});
					}

					netAmount = r.data.pricing.monroney.msrp - sumIncentives;

					let financeData = calculateFinancePayment(r.data.pricing, netAmount);
					let leaseData = calculateLeasePayment(r.data.pricing, netAmount);

					let calculatedPricing = {
						netAmount: netAmount,
						totalDiscount: sumIncentives,
						finance: financeData,
						lease: leaseData
					};
					$service.vehiclePricings.set(vehicle.id, calculatedPricing);

					$rootScope.$broadcast('ua:vehiclePricingCalculated', {
						vehicleId: vehicle.id,
						vehiclePricing:calculatedPricing
					});

					return true;
				}
			}).catch( ex => {
				console.warn("Unable to get vehicle configuration for calculate pricing: ", ex);

				return false;
			});
		};

		let calculateFinancePayment = (pricingData, netAmount) => {
			let financeData = {
				amount: netAmount,
				nbOfPaymentPerYear: 52,
			};

			let minFinanceTerm;
			if(pricingData.finance) {
				minFinanceTerm = {
					duration: pricingData.finance.term,
					rate: pricingData.finance.rate
				};
			}

			if (minFinanceTerm) {
				financeData['interestRate'] = minFinanceTerm.rate;
				financeData['term'] = minFinanceTerm.duration;

				let financeResult = calculatorService.calculateFinancePayment(financeData);
				if (financeResult) {
					financeData['payment'] = financeResult.payment;
				}
			}

			return financeData;
		};

		let calculateLeasePayment = (pricingData, netAmount) => {
			let leaseData = {
				amount: netAmount,
				nbOfPaymentPerYear: 52,
			};

			let minLeaseTerm;
			if(pricingData.lease) {
				minLeaseTerm = {
					duration: pricingData.lease.term,
					rate: pricingData.lease.rate,
					residual: pricingData.lease.residual
				};
			}

			if (minLeaseTerm) {
				//lease data doesn't be shown when default offer (type=Cash) is too high
				if (parseFloat(minLeaseTerm.rate) < $service.maxAcceptableLeaseRate) {
					leaseData['interestRate'] = minLeaseTerm.rate;
					leaseData['term'] = minLeaseTerm.duration;
					leaseData['residual'] = minLeaseTerm.duration;
					const leaseResult = calculatorService.calculateLeasePayment(leaseData);
					if (leaseResult) {
						leaseData['payment'] = leaseResult.payment;
					}
				}
			}

			return leaseData;
		};
	}

})(angular);
