(function () {
	angular
		.module('fca.buyOnline')
		.service('fcaBuyOnlineService', FcaBuyOnlineService);

	function FcaBuyOnlineService($exceptionHandler) {
		'ngInject';

		let $service = this;

		let prefix = 'buy-';

		$service.listeners = []; // called whenever something changes

		// expose ourself to the window (for debug purpose)
		window.fca = window.fca || {};
		window.fca.fcaBuyOnlineService = $service;

		/**
		 * Get the current buy object from session storage
		 *
		 * @param vin   Vin
		 * @returns BuyObject (or an empty object if there is none)
		 */
		$service.getFromStorage = (vin) => {
			let result = null;
			let item = sessionStorage.getItem($service.getKey(vin));
			if (item) {
				result = JSON.parse(item);
			} else {
				result = {};
			}
			return result;
		};

		/**
		 * Set the current object in session storage for the vin
		 *
		 * @param vin       Vin
		 * @param buyObject Buy object
		 * @returns BuyObject (or an empty object if there is none)
		 */
		$service.setInStorage = (vin, buyObject) => {
			let key = $service.getKey(vin);
			if (!!buyObject) {
				window.FcaCookieChecker.addSessionStorage(key, JSON.stringify(buyObject));
			} else {
				sessionStorage.removeItem(key);
			}

			// notify all listeners
			$service.notifyListeners(buyObject);
		};

		/**
		 * Get the session storage key for a vin
		 *
		 * @param vin
		 * @returns {string}
		 */
		$service.getKey = (vin) => {
			return prefix + vin;
		};

		/**
		 * Register a listener
		 * They will be called whenever the object is updated in storage
		 *
		 * @param listener
		 */
		$service.registerListener = (listener) => {
			$service.listeners.push(listener);
		};

		/**
		 * Notify all listeners
		 * @param updatedConfiguration
		 */
		$service.notifyListeners = (data) => {
			if ($service.listeners && $service.listeners.length > 0) {
				// call all listeners
				$service.listeners.forEach(function(listener) {
					if (listener) {
						try {
							listener(data);
						} catch(exception) {
							$exceptionHandler(exception);
						}
					}
				});
			}
		};
	}
})();
