(function(ng) {
	'use strict';

	angular
		.module('alfaromeo.contactUs')
		.controller('alfaromeoContactUsController', DialogController);

	function DialogController($scope, $rootScope, ngDialog, $translate, $sce) {
		'ngInject';

		let dialogData = $scope.ngDialogData;

		this.formUrl = $translate.instant(dialogData.formUrl);
		this.trustedFormUrl = $scope.youtubeUrl = $sce.trustAsResourceUrl(this.formUrl);
		if(window.FcaYoutubeVideoNoCookie) {
			this.trustedFormUrl = window.FcaYoutubeVideoNoCookie.updateYoutubeSrc(this.trustedFormUrl);
		}

		this.close = ($evt) => {
			$evt.preventDefault();
			$evt.stopPropagation();
			ngDialog.close();
		};
	}
})(angular);
