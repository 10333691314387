(function() {
	'use strict';

	angular
		.module('fca.daaCalculator')
		.service('daaCalculatorValidator', CalculatorValidator);

	/**
	 * @ngdoc service
	 * @name fca.daaCalculator.service:daaCalculatorValidator
	 * @description Service to validate frontend calculation by backend API service
	 * @requires $http
	 * @example
	 * <pre>
	 * ng.module('MyModule')
	 *   .controller('MyCtrl', (daaCalculatorValidator) => {
	 *     let payment;
	 *     let opts = {
	 *       financeAmount: 20000,
	 *       term: 24,
	 *       frequency: 1,
	 *       interestRate: 2.99,
	 *       downPayment: 0,
	 *       // for leasing
	 *       mileageKMs: 18000,
	 *       residual: 0,
	 *       otherNonResidualizedAmounts: 0
	 *     };
	 *     daaCalculatorValidator
	 *     .validatePayment(opts, 'leasing')
	 *     .then((r) => {
	 *       payment = r.payment;
	 *     });
	 *   });
	 * </pre>
	 */
	function CalculatorValidator($http, cookieLocation) {
		'ngInject';

		let uri = '/data/payment';

		/**
		 * @ngdoc service
		 * @name validatePayment
		 * @methodOf fca.daaCalculator.service:daaCalculatorValidator
		 * @description
		 * Call API backend to validate calculation payment
		 * @param  {Object} opts List of options for query parameters
		 * @param  {String} type Type : leasing, finance
		 * @param model
		 * @return {Object}      AngularJS promise
		 */
		this.validatePayment = (model, opts, type = 'leasing' ) => {
			let {
				financeAmount,
				term,
				frequency,
				interestRate,
				altInterestRate,
				downPayment,
				otherNonResidualizedAmounts,
				tradeInValue,
				beforeTaxDiscount,
				afterTaxDiscount,
				altBeforeTaxDiscount,
				altAfterTaxDiscount,
				isAltProgram,
				isBestPayment
			} = opts;

			function checkIfProvinceExists(location) {
				let provinceChecked = '';
				if (location === null) {
					provinceChecked = 'ON';
				} else {
					provinceChecked = location.province;
				}
				return provinceChecked;
			}

			let province = checkIfProvinceExists(cookieLocation.getLocation());

			let beforeTaxDiscountChecked = beforeTaxDiscount;
			let afterTaxDiscountChecked = afterTaxDiscount;
			let altBeforeTaxDiscountChecked = altBeforeTaxDiscount;
			let altAfterTaxDiscountChecked = altAfterTaxDiscount;

			if (interestRate === undefined) {
				interestRate = '';
			}

			if (altInterestRate === undefined) {
				altInterestRate = '';
			}

			if (beforeTaxDiscountChecked === null) {
				beforeTaxDiscountChecked = 0;
			}

			if (afterTaxDiscountChecked === null) {
				afterTaxDiscountChecked = 0;
			}

			if (altBeforeTaxDiscountChecked === null) {
				altBeforeTaxDiscountChecked = 0;
			}

			if (altAfterTaxDiscountChecked === null) {
				altAfterTaxDiscountChecked = 0;
			}

			let url = `${uri}/${type}/tax?financeAmount=${financeAmount}`;
			url += `&term=${term}&frequency=${frequency}`;
			url += `&interestRate=${interestRate}&downPayment=${downPayment}`;
			url += `&altInterestRate=${altInterestRate}`;
			url += `&otherNonResidualizedAmounts=${otherNonResidualizedAmounts}`;
			url += '&provinceCode=';
			url += province.toLowerCase();
			url += '&tradeInValue=';
			url += tradeInValue;
			url += '&beforeTaxDiscount=';
			url += beforeTaxDiscountChecked;
			url += '&afterTaxDiscount=';
			url += afterTaxDiscountChecked;
			url += '&altBeforeTaxDiscount=';
			url += altBeforeTaxDiscountChecked;
			url += '&altAfterTaxDiscount=';
			url += altAfterTaxDiscountChecked;
			url += '&isAltProgram=';
			url += isAltProgram;
			url += '&isBestPayment=';
			url += isBestPayment;
			url += '&modelYearId='
			url += model;
			if (type === 'leasing') {
				let {
					mileageKMs,
					residual
				} = opts;
				url += `&mileageKMs=${mileageKMs}&residual=${residual}`;
			}

			return $http.get(url).then((r) => {
				return r.data;
			});
		};
	}
})();
