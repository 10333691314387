(function() {
	angular
		.module('fca.countrySelector')
		.directive('fcaCountrySelector', countrySelector);

	function countrySelector() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: '$CSCtrl',
			controller: countrySelectorCtrl
		}

		function countrySelectorCtrl($element, $rootScope, $scope, gtmAnalytics) {
			'ngInject';

			const $ctrl = this;
			$ctrl.outsideCanada;
			$ctrl.preQuietDelayInMinutes = 43200; //60m * 24h * 30d

			$ctrl.$postLink = () => {
				$rootScope.$on('outside-canada-determined', (event, outsideCanada) => {
					$ctrl.outsideCanada = outsideCanada;
					console.debug('outsideCanada value from userLocation function:', $ctrl.outsideCanada);
					if ($ctrl.outsideCanada) {
						$ctrl.manageModal();
					} else {
						if (localStorage.getItem('countrySelector') != null) {
							localStorage.removeItem('countrySelector');
						}
					}
				});
			}

			$ctrl.manageModal = () => {
				if (localStorage.getItem('countrySelector') === null) {
					// user is not canadian + hasn't been on the site before
					console.debug('User doesn\'t have a countrySelector logged in the localStorage');

					$scope.$emit('fcaModal:open');
					$ctrl.setCookieWithPreQuietDelay($ctrl.preQuietDelayInMinutes);
				} else {
					// user is not canadian or hasn't been identified, but visited the site at least once
					console.debug('User has countrySelector data logged in the localStorage');
					let expiryDateIn = localStorage.getItem('countrySelector');

					if ($ctrl.isAfterPreQuietThreshold(expiryDateIn)) {
						console.debug('countrySelector is expired, must be re-show modal');
						$scope.$emit('fcaModal:open');
						$ctrl.setCookieWithPreQuietDelay($ctrl.preQuietDelayInMinutes);
					}
				}
			}

			$ctrl.setCookieWithPreQuietDelay = (minutes) => {
				let nowDate = new Date();
				let expiryDate = this.addMinutes(nowDate, minutes);
				window.FcaCookieChecker.addLocalStorage('countrySelector', expiryDate);
				console.debug('countrySelector is now set, expiryDate value is', expiryDate);
			}

			$ctrl.isAfterPreQuietThreshold = expiryDateIn => {
				let nowDate = new Date();
				let expiryDate = new Date(expiryDateIn ? expiryDateIn : null);
				let afterPreQuietThreshold = nowDate >= expiryDate;
				return afterPreQuietThreshold;
			}

			$ctrl.addMinutes = (date, minutes) => new Date(date.getTime() + minutes*60000);

			$ctrl.anaTracking = label => {
				gtmAnalytics.trackEvent('event', {
					category: 'body-header',
					label: 'country-select-' + label
				});
			}
		}
	}

	// fca-modal-is-closed
})();
