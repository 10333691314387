(function() {
    angular
        .module('fca.commonDirectives')
        .directive('fcaPersonalizationTrigger', FcaPersonalizationTrigger);

    function FcaPersonalizationTrigger() {
        return {
            restrict: 'E',
            scope: true,
            bindToController: {
                scrollRatio: '@',
                idleDuration: '@',
                zoneName: '@',
                scenarioCode: '@',
                darkMode: '<',
            },
            controllerAs: '$ctrl',
            controller: FcaPersonalizationTriggerController
        };

        function FcaPersonalizationTriggerController(fcaPartialService, $scope, $sce, functionExecutionControl, scenarioOrchestrator) {
            'ngInject';

            let $ctrl = this;

            $ctrl.pageContent = '';
            $ctrl.zoneName = '';
            $ctrl.trustedHtml = '';
            $ctrl.triggerRegistered = false;
            $ctrl.userHasinteracted = false;
            $ctrl.modalHasTriggered = false;
            $ctrl.idleState = false;
            $ctrl.idleTimer = null;
            $ctrl.analyticIdBase = null;

            let utmSource = null;
            let utmMedium = null;
            let utmCampaign = null;

            $ctrl.$onInit = () => {
                scenarioOrchestrator.registerScenario('fillTheFunnelVlpTrigger');

                $scope.$$postDigest(() => {

                    if($ctrl.scenarioCode !== 'fill-the-funnel-scenario-4-2') {
                        $ctrl.getUtmParams();
                        $ctrl.checkModalTriggerConditions();
                    } else {
                        $ctrl.checkVLPLocalStorage();
                    }

                    $ctrl.getBodyContent($ctrl.zoneName);
                    $ctrl.setAnalyticsIdBase();

                    if ($ctrl.triggerRegistered) {
                        $ctrl.userInactivityCheck();

                        $(window).on('scroll', functionExecutionControl.debounce(() => {
                            if (!$ctrl.modalHasTriggered) {
                                $ctrl.userScrollCheck();
                            }
                        }, 200));
                    }
                });
            };

            $ctrl.getBodyContent = (zone) => {
                let zoneName = zone;
                if (zoneName !== '') {

                    fcaPartialService.getZoneContent(
                        zoneName,
                        (responseContent) => {
                            $ctrl.pageContent = responseContent;
                            $ctrl.trustedHtml = $sce.trustAsHtml($ctrl.pageContent);
                        },
                        (errorMessage) => {
                            console.log(errorMessage)
                        });
                }
            };

            $ctrl.userInactivityCheck = () => {
                if(!$ctrl.userHasinteracted) {
                    $(document).on('mousedown keydown scroll', functionExecutionControl.debounce(() => {
                        $ctrl.userHasinteracted = true;
                        clearTimeout($ctrl.idleTimer);
                    }, 200));
                    $ctrl.startIdleTimer();
                } else {
                    $ctrl.startIdleTimer();
                }
            };

            $ctrl.userScrollCheck = () => {
                let scrollPosition = window.pageYOffset;
                let scrollHeight = Math.max($(document).height(), $(document.body).height());

                if(scrollPosition >= scrollHeight * $ctrl.scrollRatio / 100) {
                    $ctrl.userInactivityCheck();
                }
            };

            $ctrl.startIdleTimer = () => {
                $ctrl.idleTimer = setTimeout(() => {
                    if (scenarioOrchestrator.canShowModal('fillTheFunnelVlpTrigger')) {
                        $ctrl.idleState = true;
                        $ctrl.modalHasTriggered = true;
                        $scope.$broadcast('fcaModal:open');
                        /* remove localStorage if it exist to avoid firing scenario 4.2
                         * if scenario 4.1 already fired */

                        const localStorageItem = JSON.parse(localStorage.getItem('fillTheFunnelVlpTrigger'));

                        if (localStorageItem != null) {
                            window.localStorage.removeItem('fillTheFunnelVlpTrigger');
                        }
                    }
                }, $ctrl.idleDuration);
            };

            $ctrl.getUtmParams = () => {
                const utmParams = new URLSearchParams(window.location.search);

                if(utmParams.has('utm_source')) {
                    utmSource = utmParams.get('utm_source');
                }
                if(utmParams.has('utm_medium')) {
                    utmMedium = utmParams.get('utm_medium');
                }
                if(utmParams.has('utm_campaign')) {
                    utmCampaign = utmParams.get('utm_campaign');
                }
            };

            $ctrl.checkModalTriggerConditions = () => {
                /* Fill the funnel GA channels targeting */

                // Paid Search
                const paidSearchUtmMediumRegex = new RegExp(/cpc|ppc|search/);
                // Social Ext
                const socialExtUtmSourceRegex = new RegExp(/dfa|VerizonMedia/);
                // Paid Social
                const paidSocialUtmSourceRegex = new RegExp(/acebook|nstagram|witter|linkedin|dailymotion|pinterest|t.co/);
                const paidSocialUtmCampaignRegex = new RegExp(/([^\s])/);
                // Email (Conquest Only)
                const emailConquestUtmSourceRegex = new RegExp(/MXM|CRM/);
                const emailConquestUtmMediumRegex = new RegExp(/Email|email/);
                // Display
                const displayUtmSourceRegex = new RegExp(/radio-canada|serving-sys|um|UM/);
                const displayUtmMediumRegex = new RegExp(/display|Display|^(Banner|Video|Adjacent Unit|Audio|Custom Skin|Interstitial|Package|Header|Take-Over|Native|Mobile)$/);

                // If one of this condition is met, trigger can be registered
                if (
                    paidSearchUtmMediumRegex.test(utmSource) ||
                    ( socialExtUtmSourceRegex.test(utmSource) && (utmMedium.includes('SocialExt') || utmCampaign.includes('social')) ) ||
                    ( paidSocialUtmSourceRegex.test(utmSource) && paidSocialUtmCampaignRegex.test(utmCampaign) ) ||
                    ( emailConquestUtmSourceRegex.test(utmSource) && emailConquestUtmMediumRegex.test(utmMedium) && utmCampaign.includes('conquest') ) ||
                    ( displayUtmSourceRegex.test(utmSource) || displayUtmMediumRegex.test(utmMedium) )
                ) {
                    $ctrl.triggerRegistered = true;

                    if ($ctrl.scenarioCode === 'fill-the-funnel-scenario-4-1') {
                        $ctrl.setVLPLocalStorage();
                    }
                }
            };

            $ctrl.setVLPLocalStorage = () => {
                // Set a local storage item in case of FTF scenario 4.2 trigger

                const now = new Date().getTime();

                const ftfVlpStorage = {
                    creationDate: now,
                    expirationDate: now + 3600*1000, // 1 hour
                };

                window.FcaCookieChecker.addLocalStorage('fillTheFunnelVlpTrigger', JSON.stringify(ftfVlpStorage), window.FcaCookieChecker.cookieGroups.FUNCTIONNAL_COOKIES);
            };

            $ctrl.checkVLPLocalStorage = () => {
                // trigger for FTF scenario 4.2

                const localStorageItem = JSON.parse(localStorage.getItem('fillTheFunnelVlpTrigger'));

                if (localStorageItem != null) {
                    const now = new Date().getTime();

                    if (now < localStorageItem.expirationDate) {
                        $ctrl.triggerRegistered = true;
                    }

                    window.localStorage.removeItem('fillTheFunnelVlpTrigger');
                }
            };

            $ctrl.setAnalyticsIdBase = () => {
                if ($ctrl.scenarioCode === 'fill-the-funnel-scenario-3') {
                    $ctrl.analyticIdBase = 'personalization-scenario-3-';
                } else if ($ctrl.scenarioCode === 'fill-the-funnel-scenario-4-1') {
                    $ctrl.analyticIdBase = 'personalization-scenario-4-';
                } else {
                    $ctrl.analyticIdBase = 'personalization-scenario-vlp-';
                }
            }
        }
    }
})();
