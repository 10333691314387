(function() {
    angular
        .module('fca.uconnect')
        .directive('fcaUconnectFeatureOverlayVideo', fcaUconnectFeatureOverlayVideo);

    function fcaUconnectFeatureOverlayVideo() {
        return {
            restrict: 'A',
            scope: true,
            bindToController: {
                featureCodeSelected: '@',
                youtubeVideoId: '@',
                analyticsCategory: '@',
                analyticsId: '@'
            },
            controllerAs: '$uconnectVideoCtrl',
            controller: fcaUconnectFeatureOverlayVideoController
        };

        function fcaUconnectFeatureOverlayVideoController($scope, fcaVideoFactory, gtmAnalytics) {
            'ngInject';

            const $uconnectVideoCtrl = this;
            $uconnectVideoCtrl.player = null;
            $uconnectVideoCtrl.isLoaded = false;
            $uconnectVideoCtrl.isVideoPlaying = false;

            $scope.$on('uconnect-feature-overlay:stop-video', (event, featureCodeSelected) => {
                $uconnectVideoCtrl.stopVideo(featureCodeSelected);
            });

            $uconnectVideoCtrl.$onInit = () => {
                $uconnectVideoCtrl.loadVideo();
            };

            $uconnectVideoCtrl.loadVideo = () => {
                const html5Video = document.getElementById(`video-${$uconnectVideoCtrl.featureCodeSelected}`);

                if (html5Video) {
                    html5Video.play()
                }

                if ($uconnectVideoCtrl.youtubeVideoId !== '') {
                    fcaVideoFactory.onAPIReady.then(() => {
                        $uconnectVideoCtrl.player = new YT.Player(`youtube-video-${$uconnectVideoCtrl.featureCodeSelected}`, {
                            width: 410,
                            height: 230,
                            host: 'https://www.youtube.com',
                            videoId: `${$uconnectVideoCtrl.youtubeVideoId.replace('embed/', '')}`,
                            events: {
                                'onReady': () => {
                                    if(window.FcaYoutubeVideoNoCookie) {
                                        window.FcaYoutubeVideoNoCookie.detectCookies();
                                    }
                                    $uconnectVideoCtrl.isLoaded = true;
                                },
                                'onStateChange': (event) => {
                                    $scope.$apply(() => $uconnectVideoCtrl.isVideoPlaying = $uconnectVideoCtrl.player.getPlayerState() === 1);
                                    $uconnectVideoCtrl.ytPlaying(event, $uconnectVideoCtrl.featureCodeSelected);
                                }
                            }
                        })
                    });
                }
            };

            $uconnectVideoCtrl.playVideo = () => {
                if ($uconnectVideoCtrl.isLoaded) {
                    $uconnectVideoCtrl.player.playVideo();
                }
            };

            $uconnectVideoCtrl.pauseVideo = () => {
                if ($uconnectVideoCtrl.isLoaded) {
                    $uconnectVideoCtrl.player.pauseVideo();
                }
            };

            $uconnectVideoCtrl.stopVideo = (featureCodeSelected, reset = false) => {
                const html5Video = document.getElementById(`video-${featureCodeSelected}`);

                if (html5Video) {
                    html5Video.pause();
                    if (reset) {
                        html5Video.currentTime = 0;
                    }
                }

                if ($uconnectVideoCtrl.youtubeVideoId !== '' && $uconnectVideoCtrl.isLoaded) {
                    $uconnectVideoCtrl.player.stopVideo();
                }
            };

            $uconnectVideoCtrl.ytPlaying = (event, value) => {
                if (event.data === 1) {
                    gtmAnalytics.trackEvent('event', {
                        category: 'App-suite-' + $uconnectVideoCtrl.analyticsCategory,
                        label: `${$uconnectVideoCtrl.analyticsId}-${value}-play`
                    });
                }
            }
        }
    }
})();
